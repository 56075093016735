import AssociateElementBasePage from '../associate-element-base/associate-element-base.js';
import { define } from '@dnajs/idom';
import { __ } from '../../../factories/i18n.js';

export default class FindStatePage extends AssociateElementBasePage {
    /**
     * @inheritdoc
     */
    get name() {
        return 'find-state';
    }

    /**
     * @inheritdoc
     */
    get associationGroups() {
        if (!this.enabledGroups) {
            return [];
        }
        return ['solid', 'liquid', 'gas'].map((state) => ({
            label: __(state),
            name: state,
        }));
    }

    /**
     * @inheritdoc
     */
    checkAnswer(element) {
        if (!element) {
            return;
        }
        if (element.standard_physical_state === this.activeGroup) {
            this.correctAnswers += 1;
            this.matchedElements = this.matchedElements.concat(element);
        } else {
            this.wrongAnswers += 1;
        }
    }
}

define('find-state-page', FindStatePage);
