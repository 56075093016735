import SingleElementPage from '../single-element/single-element.js';
import { define, trust } from '@dnajs/idom';
import '../../../components/configuration-table/configuration-table.js';

export default class FindElementPage extends SingleElementPage {
    /**
     * @inheritdoc
     */
    get name() {
        return 'find-element';
    }

    /**
     * @inheritdoc
     */
    get symbol() {
        return '?';
    }

    /**
     * @inheritdoc
     */
    get title() {
        if (!this.unknownElement.electronic_configuration) {
            return '';
        }
        return trust(this.unknownElement.electronic_configuration.replace(/(\d+)(s|p|d|f)/gi, '$1<span class="spin-$2">$2</span>'));
    }

    /**
     * @inheritdoc
     */
    get focusContent() {
        return () => this.level && this.unknownElement && [
            <div class="unknown-element">
                <div class="symbol">{this.symbol}</div>
                <span class="title">{this.title}</span>
            </div>,
            <configuration-table chemicalElement={this.unknownElement} />,
        ];
    }

    /**
     * @inheritdoc
     */
    getEnabledElements() {
        return super.getEnabledElements().filter((el) => el.electronic_configuration != null);
    }

    /**
     * @inheritdoc
     */
    isElementDisabled(element) {
        if (!element) {
            return true;
        }
        if (!this.enabledGroups || !this.enabledGroups.includes(element.group)) {
            return true;
        }
        if (element.electronic_configuration == null) {
            return true;
        }
        if (this.isElementMatched(element)) {
            return true;
        }

        return false;
    }
}

define('find-element-page', FindElementPage);
