import { trust } from '@dnajs/idom';

this.configuration && [
    <p class="electronic-configuration-container">{trust(this.chemicalElement.electronic_configuration ?
        this.factory('data').getFullConfiguration(this.chemicalElement).replace(/(\d+)(s|p|d|f)/gi, '$1<span class="spin-$2">$2</span>')
        : '/'
    )}
    </p>,
    <p class="quantum-numbers">
        <span>n: {`${this.factory('data').getMainQuantumNumber(this.chemicalElement)}`}</span>
        <span>ℓ: {`${this.factory('data').getSecondaryQuantumNumber(this.chemicalElement)}`}</span>
        <span>m: {`${this.factory('data').getMagneticQuantumNumber(this.chemicalElement)}`}</span>
    </p>,
    <div class="tables">
        <table class="table1">
            <tbody>
                <tr>
                    <th>7<i>s</i></th>
                    <td>{this.c.s && (this.c.s['7'] && (this.c.s['7'] > 1 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>6<i>s</i></th>
                    <td>{this.c.s && (this.c.s['6'] && (this.c.s['6'] > 1 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>5<i>s</i></th>
                    <td>{this.c.s && (this.c.s['5'] && (this.c.s['5'] > 1 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>4<i>s</i></th>
                    <td>{this.c.s && (this.c.s['4'] && (this.c.s['4'] > 1 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>3<i>s</i></th>
                    <td>{this.c.s && (this.c.s['3'] && (this.c.s['3'] > 1 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>2<i>s</i></th>
                    <td>{this.c.s && (this.c.s['2'] && (this.c.s['2'] > 1 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>1<i>s</i></th>
                    <td>{this.c.s && (this.c.s['1'] && (this.c.s['1'] > 1 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
            </tbody>
        </table>
        <table class="table2">
            <tbody>
                <tr>
                    <th>7<i>p</i></th>
                    <td>{this.c.p && (this.c.p['7'] > 0 && (this.c.p['7'] > 3 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['7'] > 1 && (this.c.p['7'] > 4 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['7'] > 2 && (this.c.p['7'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>6<i>p</i></th>
                    <td>{this.c.p && (this.c.p['6'] > 0 && (this.c.p['6'] > 3 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['6'] > 1 && (this.c.p['6'] > 4 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['6'] > 2 && (this.c.p['6'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>5<i>p</i></th>
                    <td>{this.c.p && (this.c.p['5'] > 0 && (this.c.p['5'] > 3 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['5'] > 1 && (this.c.p['5'] > 4 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['5'] > 2 && (this.c.p['5'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>4<i>p</i></th>
                    <td>{this.c.p && (this.c.p['4'] > 0 && (this.c.p['4'] > 3 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['4'] > 1 && (this.c.p['4'] > 4 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['4'] > 2 && (this.c.p['4'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>3<i>p</i></th>
                    <td>{this.c.p && (this.c.p['3'] > 0 && (this.c.p['3'] > 3 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['3'] > 1 && (this.c.p['3'] > 4 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['3'] > 2 && (this.c.p['3'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>2<i>p</i></th>
                    <td>{this.c.p && (this.c.p['2'] > 0 && (this.c.p['2'] > 3 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['2'] > 1 && (this.c.p['2'] > 4 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.p && (this.c.p['2'] > 2 && (this.c.p['2'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
            </tbody>
        </table>
        <table class="table3">
            <tbody>
                <tr>
                    <th>6<i>d</i></th>
                    <td>{this.c.d && (this.c.d['6'] > 0 && (this.c.d['6'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['6'] > 1 && (this.c.d['6'] > 6 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['6'] > 2 && (this.c.d['6'] > 7 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['6'] > 3 && (this.c.d['6'] > 8 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['6'] > 4 && (this.c.d['6'] > 9 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>5<i>d</i></th>
                    <td>{this.c.d && (this.c.d['5'] > 0 && (this.c.d['5'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['5'] > 1 && (this.c.d['5'] > 6 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['5'] > 2 && (this.c.d['5'] > 7 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['5'] > 3 && (this.c.d['5'] > 8 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['5'] > 4 && (this.c.d['5'] > 9 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>4<i>d</i></th>
                    <td>{this.c.d && (this.c.d['4'] > 0 && (this.c.d['4'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['4'] > 1 && (this.c.d['4'] > 6 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['4'] > 2 && (this.c.d['4'] > 7 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['4'] > 3 && (this.c.d['4'] > 8 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['4'] > 4 && (this.c.d['4'] > 9 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>3<i>d</i></th>
                    <td>{this.c.d && (this.c.d['3'] > 0 && (this.c.d['3'] > 5 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['3'] > 1 && (this.c.d['3'] > 6 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['3'] > 2 && (this.c.d['3'] > 7 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['3'] > 3 && (this.c.d['3'] > 8 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.d && (this.c.d['3'] > 4 && (this.c.d['3'] > 9 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
            </tbody>
        </table>
        <table class="table4">
            <tbody>
                <tr>
                    <th>5<i>f</i></th>
                    <td>{this.c.f && (this.c.f['5'] > 0 && (this.c.f['5'] > 7 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['5'] > 1 && (this.c.f['5'] > 8 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['5'] > 2 && (this.c.f['5'] > 9 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['5'] > 3 && (this.c.f['5'] > 10 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['5'] > 4 && (this.c.f['5'] > 11 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['5'] > 5 && (this.c.f['5'] > 12 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['5'] > 6 && (this.c.f['5'] > 13 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
                <tr>
                    <th>4<i>f</i></th>
                    <td>{this.c.f && (this.c.f['4'] > 0 && (this.c.f['4'] > 7 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['4'] > 1 && (this.c.f['4'] > 8 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['4'] > 2 && (this.c.f['4'] > 9 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['4'] > 3 && (this.c.f['4'] > 10 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['4'] > 4 && (this.c.f['4'] > 11 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['4'] > 5 && (this.c.f['4'] > 12 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                    <td>{this.c.f && (this.c.f['4'] > 6 && (this.c.f['4'] > 13 ? <span class="spin2" /> : <span class="spin1" />))}</td>
                </tr>
            </tbody>
        </table>
    </div>,
];
