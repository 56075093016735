import { App, PageViewComponent } from '@chialab/synapse';
import { merge } from '@chialab/proteins';
import ROUTES from './config/routes.js';
import DataFactory from './factories/data';
import SearchFactory from './factories/search';
import I18NFactory from './factories/i18n.js';
import './components/tp-menu/tp-menu.js';
import './components/rotate-device/rotate-device.js';
import './components/native-apps-dialog/native-apps-dialog.js';
import LOCALE_IT from './locales/it.json';
import LOCALE_EN from './locales/en.json';
import { IS_KIDS } from './config/features.js';

async function copyPDF() {
    return await new Promise((resolve) => {
        window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, (fs) => {
            window.resolveLocalFileSystemURL(`${window.cordova.file.applicationDirectory}www/assets/zanichelli_tavola_periodica.pdf`, (fileEntry) => {
                fileEntry.copyTo(fs.root, 'zanichelli_tavola_periodica.pdf', resolve, resolve);
            }, resolve);
        }, resolve);
    });
}

export default class PeriodicTableApp extends App {
    /**
     * @inheritdoc
     */
    static get View() {
        return PageViewComponent;
    }

    /**
     * @inheritdoc
     */
    get routeOptions() {
        return {
            base: self.cordova ? `${location.pathname}#` : '',
        };
    }

    /**
     * @inheritdoc
     */
    get routeRules() {
        return ROUTES;
    }

    /**
     * @inheritdoc
     */
    static get injectors() {
        return merge(super.injectors || {}, {
            data: DataFactory,
            search: SearchFactory,
            i18n: [I18NFactory, { it: LOCALE_IT, en: LOCALE_EN }, 'it'],
        });
    }

    /**
     * @inheritdoc
     */
    async initialize(...args) {
        await super.initialize(...args);
        if (window.cordova) {
            await copyPDF();
            this.element.setAttribute('data-platform', window.cordova.platformId);
        }

        return this;
    }

    /**
     * @inheritdoc
     */
    async beforeRoute(lang, ...args) {
        if (lang) {
            this.currentLang = lang;
            const i18n = this.factory('i18n');
            i18n.setLanguage(lang);
            if (IS_KIDS) {
                document.title = `${i18n.translate('periodic table of elements kids')} Zanichelli`;
            } else {
                document.title = `${i18n.translate('periodic table of elements')} Zanichelli`;
            }

            document.querySelector('meta[name="description"]')?.setAttribute('content', i18n.translate('app_description'));
        }
        return await super.beforeRoute(lang, ...args);
    }

    async dispatchController(...args) {
        if (this.changingActiveElement) {
            this.trigger('navigation', this.router.current);
            throw 'just changing the active element';
        }
        return await super.dispatchController(...args);
    }

    /**
     * @inheritdoc
     */
    throwException(err) {
        if (err === 'just changing the active element') {
            return true;
        }
        return super.throwException(err);
    }

    /**
     * @inheritdoc
     */
    afterRoute(...args) {
        this.trigger('navigation', this.router.current);
        return super.afterRoute(...args);
    }

    /**
     * @inheritdoc
     */
    render() {
        return [
            <header><nav is="tp-menu"></nav></header>,
            super.render(),
            !window.cordova && <dialog is="rotate-device" />,
            !window.cordova && <dialog is="native-apps-dialog" storage />,
        ];
    }

    /**
     * @inheritdoc
     */
    handleLink(ev, link, ...args) {
        if (self.cordova && link) {
            const target = link.getAttribute('target');
            const href = link.getAttribute('href');
            if (target === '_system' && href.endsWith('.pdf')) {
                ev.stopPropagation();
                ev.preventDefault();
                window.resolveLocalFileSystemURL('cdvfile://localhost/persistent/zanichelli_tavola_periodica.pdf', (fileEntry) => {
                    window.cordova.plugins.fileOpener2.open(
                        fileEntry.toURL(),
                        'application/pdf',
                        {
                            error: () => { },
                            success: () => { },
                        }
                    );
                });
                return true;
            }
        }
        return super.handleLink(ev, link, ...args);
    }
}
