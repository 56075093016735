import { IS_KIDS } from '../../config/features.js';

<div class="meter">
    <div class="meter-bars-box">
        <div class="dragger-container">
            {!IS_KIDS &&
                <div class="temperature temperature-sm" name="K">
                    <input name="K" type="number" size="4" value={this.temperature}></input>
                    <span class="unit">K</span>
                </div>}
            <div class="temperature temperature-l" name="C">
                <input name="C" type="number" size="4" value={this.temperatureC}></input>
                <span class="unit">°C</span>
            </div>
            <div class="dragger"></div>
        </div>
        <div class="meter-fill"></div>
    </div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" x="0px" y="0px" viewBox="0 0 1258.96 59.05" style="enable-background:new 0 0 1258.96 59.05;">
        <defs>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="49.045" y1="17.95" x2="49.045" y2="46.5319" gradientTransform="matrix(1 0 0 -1 0 62)">
                <stop offset="0" style="stop-color:#000000" />
                <stop offset="0.118" style="stop-color:#453633" />
                <stop offset="0.5" style="stop-color:#860B17" />
                <stop offset="0.544" style="stop-color:#87141A" />
                <stop offset="0.6082" style="stop-color:#892522" />
                <stop offset="0.6847" style="stop-color:#8D3A31" />
                <stop offset="0.7707" style="stop-color:#925347" />
                <stop offset="0.8647" style="stop-color:#997167" />
                <stop offset="0.9638" style="stop-color:#A39895" />
                <stop offset="1" style="stop-color:#A7A9AC" />
            </linearGradient>
            <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="677.475" y1="3.4049" x2="677.475" y2="59.8832" gradientTransform="matrix(1 0 0 -1 0 62)">
                <stop offset="0" style="stop-color:#BCBEC0" />
                <stop offset="0.0114" style="stop-color:#C0C2C4" />
                <stop offset="0.0771" style="stop-color:#D6D7D9" />
                <stop offset="0.1498" style="stop-color:#E7E7E8" />
                <stop offset="0.2322" style="stop-color:#F3F3F4" />
                <stop offset="0.3323" style="stop-color:#FBFBFB" />
                <stop offset="0.5" style="stop-color:#FFFFFF" />
                <stop offset="0.6685" style="stop-color:#FBFBFB" />
                <stop offset="0.7685" style="stop-color:#F3F3F4" />
                <stop offset="0.8505" style="stop-color:#E7E8E8" />
                <stop offset="0.9229" style="stop-color:#D6D7D9" />
                <stop offset="0.9882" style="stop-color:#C1C2C4" />
                <stop offset="1" style="stop-color:#BCBEC0" />
            </linearGradient>
        </defs>
        <path style={`fill: url('${location.origin}${location.pathname}#SVGID_1_')`} d="M7.34,44.05C3.84,44.05,1,41.2,1,37.71V21.34C1,17.85,3.84,15,7.34,15h83.41c3.5,0,6.34,2.84,6.34,6.34v16.37
        c0,3.5-2.84,6.34-6.34,6.34H7.34z" />
        <path fill="#414042" d="M90.75,16c2.94,0,5.34,2.4,5.34,5.34v16.37c0,2.94-2.4,5.34-5.34,5.34H7.34C4.4,43.05,2,40.65,2,37.71V21.34
        C2,18.4,4.4,16,7.34,16H90.75 M90.75,14H7.34C3.3,14,0,17.31,0,21.34v16.37c0,4.04,3.3,7.34,7.34,7.34h83.41
        c4.04,0,7.34-3.3,7.34-7.34V21.34C98.09,17.31,94.79,14,90.75,14L90.75,14z" />
        <path style={`fill: url('${location.origin}${location.pathname}#SVGID_2_')`} d="M103.54,58.05c-3.61,0-6.54-2.94-6.54-6.54V7.54C97,3.94,99.93,1,103.54,1h1147.87c3.61,0,6.54,2.94,6.54,6.54
        v43.97c0,3.61-2.94,6.54-6.54,6.54C1251.41,58.05,103.54,58.05,103.54,58.05z" />
        <path fill="#414042" d="M1251.41,2c3.06,0,5.54,2.49,5.54,5.54v43.97c0,3.06-2.49,5.54-5.54,5.54H103.54c-3.06,0-5.54-2.49-5.54-5.54
        V7.54C98,4.49,100.48,2,103.54,2H1251.41 M1251.41,0H103.54C99.39,0,96,3.39,96,7.54v43.97c0,4.15,3.39,7.54,7.54,7.54h1147.87
        c4.15,0,7.54-3.39,7.54-7.54V7.54C1258.96,3.39,1255.56,0,1251.41,0L1251.41,0z" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="124.69" y1="14.62" x2="124.69" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="132.27" y1="26.76" x2="132.27" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="140.76" y1="14.62" x2="140.76" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="148.35" y1="26.76" x2="148.35" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="157.16" y1="14.62" x2="157.16" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="164.74" y1="26.76" x2="164.74" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="173.12" y1="14.62" x2="173.12" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="180.71" y1="26.76" x2="180.71" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="189.42" y1="14.62" x2="189.42" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="197.01" y1="26.76" x2="197.01" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="205.49" y1="14.62" x2="205.49" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="213.08" y1="26.76" x2="213.08" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="221.89" y1="14.62" x2="221.89" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="229.48" y1="26.76" x2="229.48" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="237.85" y1="14.62" x2="237.85" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="245.44" y1="26.76" x2="245.44" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="254.16" y1="14.62" x2="254.16" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="261.74" y1="26.76" x2="261.74" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="270.23" y1="14.62" x2="270.23" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="277.82" y1="26.76" x2="277.82" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="286.62" y1="14.62" x2="286.62" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="294.21" y1="26.76" x2="294.21" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="302.59" y1="14.62" x2="302.59" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="310.17" y1="26.76" x2="310.17" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="319.11" y1="14.62" x2="319.11" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="326.7" y1="26.76" x2="326.7" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="335.18" y1="14.62" x2="335.18" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="342.77" y1="26.76" x2="342.77" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="351.58" y1="14.62" x2="351.58" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="359.16" y1="26.76" x2="359.16" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="367.54" y1="14.62" x2="367.54" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="375.13" y1="26.76" x2="375.13" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="383.84" y1="14.62" x2="383.84" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="391.43" y1="26.76" x2="391.43" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="399.92" y1="14.62" x2="399.92" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="407.5" y1="26.76" x2="407.5" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="416.31" y1="14.62" x2="416.31" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="423.9" y1="26.76" x2="423.9" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="432.27" y1="14.62" x2="432.27" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="439.86" y1="26.76" x2="439.86" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="448.57" y1="14.62" x2="448.57" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="456.16" y1="26.76" x2="456.16" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="464.64" y1="14.62" x2="464.64" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="472.23" y1="26.76" x2="472.23" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="481.04" y1="14.62" x2="481.04" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="488.62" y1="26.76" x2="488.62" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="497" y1="14.62" x2="497" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="504.59" y1="26.76" x2="504.59" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="513.3" y1="14.62" x2="513.3" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="520.89" y1="26.76" x2="520.89" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="529.38" y1="14.62" x2="529.38" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="536.96" y1="26.76" x2="536.96" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="545.77" y1="14.62" x2="545.77" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="553.36" y1="26.76" x2="553.36" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="561.73" y1="14.62" x2="561.73" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="569.32" y1="26.76" x2="569.32" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="578.04" y1="14.62" x2="578.04" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="585.62" y1="26.76" x2="585.62" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="594.11" y1="14.62" x2="594.11" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="601.7" y1="26.76" x2="601.7" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="610.5" y1="14.62" x2="610.5" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="618.09" y1="26.76" x2="618.09" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="626.47" y1="14.62" x2="626.47" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="634.05" y1="26.76" x2="634.05" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="642.99" y1="14.62" x2="642.99" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="650.58" y1="26.76" x2="650.58" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="659.06" y1="14.62" x2="659.06" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="666.65" y1="26.76" x2="666.65" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="675.46" y1="14.62" x2="675.46" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="683.04" y1="26.76" x2="683.04" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="691.42" y1="14.62" x2="691.42" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="699.01" y1="26.76" x2="699.01" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="707.72" y1="14.62" x2="707.72" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="715.31" y1="26.76" x2="715.31" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="723.8" y1="14.62" x2="723.8" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="731.38" y1="26.76" x2="731.38" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="740.19" y1="14.62" x2="740.19" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="747.78" y1="26.76" x2="747.78" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="756.15" y1="14.62" x2="756.15" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="763.74" y1="26.76" x2="763.74" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="772.14" y1="14.62" x2="772.14" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="779.72" y1="26.76" x2="779.72" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="788.21" y1="14.62" x2="788.21" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="795.8" y1="26.76" x2="795.8" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="804.6" y1="14.62" x2="804.6" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="812.19" y1="26.76" x2="812.19" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="820.57" y1="14.62" x2="820.57" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="828.15" y1="26.76" x2="828.15" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="836.87" y1="14.62" x2="836.87" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="844.46" y1="26.76" x2="844.46" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="852.94" y1="14.62" x2="852.94" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="860.53" y1="26.76" x2="860.53" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="869.34" y1="14.62" x2="869.34" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="876.92" y1="26.76" x2="876.92" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="885.3" y1="14.62" x2="885.3" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="892.89" y1="26.76" x2="892.89" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="901.6" y1="14.62" x2="901.6" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="909.19" y1="26.76" x2="909.19" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="917.68" y1="14.62" x2="917.68" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="925.26" y1="26.76" x2="925.26" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="934.07" y1="14.62" x2="934.07" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="941.66" y1="26.76" x2="941.66" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="950.03" y1="14.62" x2="950.03" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="957.62" y1="26.76" x2="957.62" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="966.56" y1="14.62" x2="966.56" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="974.14" y1="26.76" x2="974.14" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="982.63" y1="14.62" x2="982.63" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="990.22" y1="26.76" x2="990.22" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="999.03" y1="14.62" x2="999.03" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="1006.61" y1="26.76" x2="1006.61" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="1014.99" y1="14.62" x2="1014.99" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="1022.58" y1="26.76" x2="1022.58" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="1031.29" y1="14.62" x2="1031.29" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="1038.88" y1="26.76" x2="1038.88" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="1047.36" y1="14.62" x2="1047.36" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="1054.95" y1="26.76" x2="1054.95" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="1063.76" y1="14.62" x2="1063.76" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="1071.35" y1="26.76" x2="1071.35" y2="42.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="1079.72" y1="14.62" x2="1079.72" y2="40.05" />
        <line fill="none" stroke="#414042" stroke-width="2" stroke-miterlimit="10" x1="1096.57" y1="14.62" x2="1096.57" y2="40.05" />
        <line fill="none" stroke="#58595B" stroke-width="2" stroke-miterlimit="10" x1="1087.31" y1="26.76" x2="1087.31" y2="42.05" />
    </svg>
</div>;
