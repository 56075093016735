import { Component } from '@chialab/synapse';
import { prop, define } from '@dnajs/idom';
import template from './table-element.jsx';

export class TableElementComponent extends Component {
    /**
     * @inheritdoc
     */
    get template() {
        return template;
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            lang: String,
            chemicalElement: prop(Object).observe(() => {
                this.group = this.chemicalElement && this.chemicalElement.group;
                this.node.id = this.chemicalElement && this.chemicalElement.atomic_number;
            }),
            category: String,
            temperature: prop(Number).observe(this.setState),
            group: prop(String).attribute('data-group'),
        };
    }

    /**
     * @inheritdoc
     */
    propertyChangedCallback(propName, oldValue, newValue) {
        if (propName === 'temperature') {
            return;
        }
        return super.propertyChangedCallback(propName, oldValue, newValue);
    }

    /**
     * Set physical state of the element at current temperature.
     *
     * @memberof TableElementComponent
     */
    setState() {
        if (!this.chemicalElement) {
            return;
        }
        const meltingTemp = this.chemicalElement.melting_temperature;
        const boilingTemp = this.chemicalElement.boiling_temperature;

        this.node.setAttribute('data-state', 'solid');
        if (meltingTemp !== null && this.temperature >= meltingTemp) {
            this.node.setAttribute('data-state', 'liquid');
        }
        if (boilingTemp !== null && this.temperature >= boilingTemp) {
            this.node.setAttribute('data-state', 'gas');
        }
    }
}

define('table-element', TableElementComponent);
