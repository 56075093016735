/**
 * Electronic configuration levels data.
 */
export default [
    {
        s: 2,
    },
    {
        s: 2,
        p: 6,
    },
    {
        s: 2,
        p: 6,
        d: 10,
    },
    {
        s: 2,
        p: 6,
        d: 10,
        f: 14,
    },
    {
        s: 2,
        p: 6,
        d: 10,
        f: 14,
    },
    {
        s: 2,
        p: 6,
        d: 10,
    },
    {
        s: 2,
        p: 6,
    },
];
