import { Component } from '@chialab/synapse';
import { DOM, define, prop } from '@dnajs/idom';
import '../table-element/table-element.js';
import '../element-card/element-card.js';
import template from './periodic-table.jsx';

/**
 * @class PeriodicTableComponent
 * @extends {Component}
 */
export class PeriodicTableComponent extends Component {
    /**
     * @inheritdoc
     */
    get template() {
        return template;
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'mouseover': (event) => {
                const element = event.target.closest('td .table-element');
                if (element && !element.getAttribute('data-disabled')) {
                    const chemicalElement = DOM.getNodeComponent(element).chemicalElement;
                    this.highlightElements = [chemicalElement];
                } else if (this.highlightElements && this.highlightElements.length) {
                    this.highlightElements = [];
                }
            },
            'click .credits-toggle': () => {
                let dialogElement = this.querySelector('.credits');
                dialogElement.hidden = false;
            },
            'click .close-credits': () => {
                let dialogElement = this.querySelector('.credits');
                dialogElement.hidden = true;
            },
        };
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            lang: String,
            activeElement: prop(Object).observe(() => {
                if (!this.activeElement) {
                    return;
                }
            }),
            activeGroups: prop(Array).default([]),
            highlightElements: Array,
            category: prop(String).observe(this.processCategory),
            chemicalElements: prop(Array).observe(this.processCategory),
            importanceMap: Array,
            isElementDisabled: Function, // ♿️
            isElementCovered: Function,
            isElementMatched: Function,
            temperature: Number,
            focusContent: Function,
            toolsContent: Function,
        };
    }

    get activeRow() {
        const data = this.factory('data');
        if (this.activeElement) {
            return data.getElementRow(this.activeElement);
        }
        return null;
    }

    get activeColumn() {
        const data = this.factory('data');
        if (this.activeElement) {
            return data.getElementColumn(this.activeElement);
        }
        return null;
    }

    get highlightRows() {
        const data = this.factory('data');
        if (this.highlightElements) {
            return this.highlightElements.map(data.getElementRow);
        }
        return [];
    }

    get highlightColumns() {
        const data = this.factory('data');
        if (this.highlightElements) {
            return this.highlightElements.map(data.getElementColumn);
        }
        return [];
    }

    /**
     * Generate elements importance map to assign a color based on category value.
     *
     * @memberof PeriodicTableComponent
     */
    processCategory() {
        if (!this.category || !this.chemicalElements) {
            return;
        }

        const elements = this.chemicalElements;

        if (!(this.category in elements[0])) {
            return;
        }

        switch (this.category) {
            case 'discovery':
            case 'oxidation_numbers':
            case 'electronic_configuration':
                return;
        }

        let mapped = [];
        let min;
        let max;
        let values = [];

        elements.forEach((element) => {
            let value = element[this.category];
            value = parseFloat(`${value}`.replace(/[[\]]/g, ''));
            values.push(value);
            if (isNaN(value)) {
                return;
            }
            if (min === undefined || min > element[this.category]) {
                min = value;
            }
            if (max === undefined || max < element[this.category]) {
                max = value;
            }
        });

        let diff = (max - min) / 10;
        values.forEach((value) => {
            if (isNaN(value)) {
                mapped.push(-1);
                return;
            }
            let importance = Math.max(Math.min(Math.floor(value / diff), 10), 0);
            mapped.push(`${importance}`);
        });

        this.importanceMap = mapped;
    }

    /**
     * @inheritdoc
     */
    propertyChangedCallback(propName, oldValue, newValue) {
        if (propName === 'temperature') {
            let elements = this._elements || Array.from(this.querySelectorAll('.table-element')).map(DOM.getNodeComponent.bind(DOM));
            if (elements.length) {
                this._elements = elements;
            }
            for (let i = 0, len = elements.length; i < len; i++) {
                elements[i].temperature = this.temperature;
            }
            return;
        }
        return super.propertyChangedCallback(propName, oldValue, newValue);
    }
}

define('periodic-table', PeriodicTableComponent, {
    extends: 'table',
});
