import { prop, define } from '@dnajs/idom';
import { Component } from '@chialab/synapse';
import template from './element-card.jsx';

export class ElementCardComponent extends Component {
    /**
     * @inheritdoc
     */
    get template() {
        return template;
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            lang: String,
            chemicalElement: prop(Object).observe(() => {
                this.setCardPosition();
            }),
            position: prop(String).attribute('data-position'),
            showPoster: prop(Boolean).default(true),
            showDescription: prop(Boolean).default(true),
            content: Function,
            extended: prop(Boolean).default(false).attribute('data-extended'),
        };
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click footer button.next': () => { this.trigger('element-card:next', { chemicalElement: this.chemicalElement }); },
            'click footer button.previous': () => { this.trigger('element-card:previous', { chemicalElement: this.chemicalElement }); },
            'click footer button.close': () => { this.trigger('element-card:close', { component: this }); },
            'click footer button.description-btn': this.toggleInfo,
        };
    }

    get lang() {
        return this.factory('i18n').currentLanguage;
    }

    /**
     * @inheritdoc
     */
    connectedCallback() {
        super.connectedCallback();
        this._setCardPosition = () => {
            setTimeout(() => {
                this.setCardPosition();
            });
        };
        window.addEventListener('resize', this._setCardPosition);
    }

    /**
     * @inheritdoc
     */
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('resize', this._setCardPosition);
    }

    /**
     * @inheritdoc
     */
    render(...args) {
        super.render(...args);
        setTimeout(() => {
            this.setCardPosition();
        });
    }

    /**
     * Show or hide chemical element description.
     */
    toggleInfo() {
        this.extended = !this.extended;
    }

    /**
     * Set the card position relative to the active element.
     */
    setCardPosition() {
        if (!this.chemicalElement) {
            return;
        }
        const page = this.node.offsetParent;
        if (!page) {
            return setTimeout(() => {
                this.setCardPosition();
            }, 1);
        }
        const element = page.querySelector(`.table-element[id="${this.chemicalElement.atomic_number}"]`);
        if (!element) {
            return setTimeout(() => {
                this.setCardPosition();
            }, 1);
        }
        const pageRect = page.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        let elementPosition = {
            width: elementRect.width,
            height: elementRect.height,
            x: elementRect.left - pageRect.left,
            y: elementRect.top - pageRect.top,
            rate: (elementRect.left - pageRect.left) / pageRect.width,
        };
        if (elementPosition.rate > 0.5) {
            this.node.style.transform = this.node.style.webkitTransform = `translate3d(${elementPosition.x - elementPosition.width * 0.5}px, 0, 0) translate3d(-${this.node.clientWidth}px, 0, 0)`;
        } else {
            this.node.style.transform = this.node.style.webkitTransform = `translate3d(${elementPosition.x + elementPosition.width * 1.5}px, 0, 0)`;
        }
    }
}

define('element-card', ElementCardComponent);
