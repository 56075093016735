import ExerciseBasePage from '../exercise-base/exercise-base.js';
import { prop } from '@dnajs/idom';
import '../../../components/groups-list/groups-list.js';

export default class AssociateElementBasePage extends ExerciseBasePage {
    /**
     * @inheritdoc
     */
    get properties() {
        return {
            activeGroup: prop(String),
        };
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click .groups-list li button': (ev, target) => {
                this.activeGroup = target.closest('[data-group]').dataset.group;
            },
        };
    }

    /**
     * @inheritdoc
     */
    get focusContent() {
        return () => (this.enabledElements.length !== this.matchedElements.length) &&
            <groups-list groups={this.associationGroups} activeGroup={this.activeGroup} />;
    }

    /**
     * Return association groups.
     */
    get associationGroups() {
        return [];
    }

    /**
     * @inheritdoc
     */
    checkAnswer(element) {
        if (!element) {
            return;
        }
        if (element.group === this.activeGroup) {
            this.correctAnswers += 1;
            this.matchedElements = this.matchedElements.concat(element);
        } else {
            this.wrongAnswers += 1;
        }
    }

    /**
     * @inheritdoc
     */
    isElementDisabled(element) {
        if (!this.enabledGroups || !element || !this.activeGroup) {
            return true;
        }
        return !this.enabledGroups.some((group) => group === element.group) || this.isElementMatched(element);
    }

    /**
     * @inheritdoc
     */
    resetAnswers() {
        super.resetAnswers();
        this.activeGroup = null;
    }
}
