import { BaseComponent, define, prop } from '@dnajs/idom';
import { toKelvin, toCelsius } from '../../helpers/temperature.js';
import template from './thermometer.jsx';

/**
 * Maximum thermometer temperature value (Kelvin scale).
 */
const MAX_TEMPERATURE_K = 6000;
const MIN_TEMPERATURE_K = 0;

export class ThermometerComponent extends BaseComponent {
    /**
     * @inheritdoc
     */
    get template() {
        return template;
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            temperature: prop(Number)
                .default(MIN_TEMPERATURE_K)
                .setter((val) => {
                    let validValue = this.setTemperature(val);
                    this.trigger('temperature-change', { temperature: validValue });
                    return validValue;
                }),
        };
    }

    /**
     * Getter for temperature in degrees Celsius.
     */
    get temperatureC() {
        return toCelsius(this.temperature);
    }

    /**
     * Get celsius input node.
     */
    get celsiusInput() {
        if (!this._celsiusInput) {
            this._celsiusInput = this.querySelector('input[name="C"]');
        }
        return this._celsiusInput;
    }

    /**
     * Get kelvin input node.
     */
    get kelvinInput() {
        if (!this._kelvinInput) {
            this._kelvinInput = this.querySelector('input[name="K"]');
        }
        return this._kelvinInput;
    }

    /**
     * Get `dragger-container` node.
     */
    get draggerContainer() {
        if (!this._draggerContainerNode) {
            this._draggerContainerNode = this.querySelector('.dragger-container');
        }
        return this._draggerContainerNode;
    }

    /**
     * Get `meter-fill` node.
     */
    get meterFill() {
        if (!this._meterFillNode) {
            this._meterFillNode = this.querySelector('.meter-fill');
        }
        return this._meterFillNode;
    }

    /**
     * Get `meter-bars-box` node.
     */
    get meterBars() {
        if (!this._meterBarsNode) {
            this._meterBarsNode = this.querySelector('.meter-bars-box');
        }
        return this._meterBarsNode;
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click .temperature-sm': this.switchTemperatureIndicators,
            'change input[name="K"]': (ev, target) => {
                let value = parseInt(target.value);
                if (isNaN(value)) {
                    value = this.temperature;
                }
                this.temperature = value;
            },
            'change input[name="C"]': (ev, target) => {
                let value = parseInt(target.value);
                if (isNaN(value)) {
                    value = this.temperature;
                } else {
                    value = toKelvin(value);
                }
                const newVal = value;
                this.temperature = newVal;
            },
            'touchstart .dragger-container': (ev) => {
                this.useTouchEvents = true;
                if (!ev.target.closest('input')) {
                    ev.preventDefault();
                }

                if (ev.touches && ev.touches.length > 1) {
                    return;
                }

                let initialX = ev.touches[0].clientX;
                let initialTemp = this.temperature;

                const moveCallback = (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    let width = this.meterBars.offsetWidth;
                    let shift = ev.changedTouches[0].clientX - initialX;
                    let diff = Math.round(shift * MAX_TEMPERATURE_K / width);
                    // Temperature range goes from 0 to 6000, so the shift needs a proportion with `meterBars` width.
                    this.temperature = Math.max(Math.min(initialTemp + diff, MAX_TEMPERATURE_K), MIN_TEMPERATURE_K);
                };

                const cancelCallback = () => {
                    document.removeEventListener('touchmove', moveCallback);
                    document.removeEventListener('touchend', cancelCallback);
                    document.removeEventListener('touchcancel', cancelCallback);
                };

                document.addEventListener('touchmove', moveCallback);
                document.addEventListener('touchend', cancelCallback);
                document.addEventListener('touchcancel', cancelCallback);
            },
            'mousedown .dragger': (ev) => {
                if (this.useTouchEvents) {
                    return;
                }

                let initialX = ev.clientX;
                let initialTemp = this.temperature;

                const moveCallback = (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    let width = this.meterBars.offsetWidth;
                    let shift = ev.clientX - initialX;
                    let diff = Math.round(shift * MAX_TEMPERATURE_K / width);
                    // Temperature range goes from 0 to 6000, so the shift needs a proportion with `meterBars` width.
                    this.temperature = Math.max(Math.min(initialTemp + diff, MAX_TEMPERATURE_K), MIN_TEMPERATURE_K);
                };

                const cancelCallback = () => {
                    document.removeEventListener('mousemove', moveCallback);
                    document.removeEventListener('mouseup', cancelCallback);
                    document.removeEventListener('mouseleave', cancelCallback);
                };

                document.addEventListener('mousemove', moveCallback);
                document.addEventListener('mouseup', cancelCallback);
                document.addEventListener('mouseleave', cancelCallback);
            },
        };
    }

    /**
     * @inheritdoc
     */
    connectedCallback() {
        super.connectedCallback();
        this.setTemperature(this.temperature);
    }

    /**
     * Switch small temperature indicator position with the big one.
     *
     * @param {Event} ev Click event triggered by small indicator.
     * @memberof ThermometerComponent
     */
    switchTemperatureIndicators(ev) {
        if (ev.target.nodeName === 'INPUT') {
            return;
        }
        const sm = ev.target.closest('.temperature-sm');
        const l = this.node.querySelector('.temperature-l');
        sm.classList.remove('temperature-sm');
        sm.classList.add('temperature-l');
        l.classList.remove('temperature-l');
        l.classList.add('temperature-sm');
    }

    setTemperature(value) {
        let validValue = value;

        if (value > MAX_TEMPERATURE_K) {
            validValue = MAX_TEMPERATURE_K;
        } else if (value < MIN_TEMPERATURE_K) {
            validValue = MIN_TEMPERATURE_K;
        }

        if (this.celsiusInput) {
            this.celsiusInput.value = toCelsius(validValue);
        }
        if (this.kelvinInput) {
            this.kelvinInput.value = validValue;
        }

        if (this.meterBars) {
            let shift = 100 * validValue / MAX_TEMPERATURE_K;
            this.draggerContainer.style.left = `${shift}%`;
            this.meterFill.style.width = `${shift}%`;
        }

        return validValue;
    }
}

define('thermometer', ThermometerComponent);
