import { PageViewComponent } from '@chialab/synapse';
import { mix, define, trust } from '@dnajs/idom';
import { PageMixin } from '../../mixins/page.js';
import { SearchMixin } from '../../mixins/search.js';
import { CardMixin } from '../../mixins/card.js';
import '../../components/periodic-table/periodic-table.js';
import '../../components/periodic-table/periodic-table.js';
import '../../components/configuration-table/configuration-table.js';
import '../../components/configuration-graph/configuration-graph.js';

export default class ConfigurationPage extends mix(PageViewComponent).with(PageMixin, CardMixin, SearchMixin) {
    /**
     * @inheritdoc
     */
    get template() {
        return () => [
            <table is="periodic-table"
                lang={this.lang}
                chemicalElements={this.chemicalElements}
                category="electronic_configuration"
                isElementDisabled={this.isElementDisabled}
                highlightElements={this.highlightElements}
                activeElement={this.activeElement}
            />,
            this.activeElement &&
            this.activeElement.electronic_configuration != null &&
            <element-card
                lang={this.lang}
                showPoster={false}
                showDescription={false}
                extended={true}
                hidden={!this.activeElement}
                chemicalElement={this.activeElement}
                content={() =>
                    <section class="data">
                        <p class="configuration-value-compact">{trust(this.activeElement.electronic_configuration.replace(/(\d+)(s|p|d|f)/gi, '$1<span class="spin-$2">$2</span>'))}</p>
                        <configuration-graph chemicalElement={this.activeElement} />
                        <configuration-table chemicalElement={this.activeElement} />
                    </section>
                }
            />,
        ];
    }

    /**
     * @inheritdoc
     */
    isElementDisabled(element) {
        return !element['electronic_configuration'];
    }
}

define('configuration-page', ConfigurationPage);
