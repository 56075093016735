import { PageViewComponent } from '@chialab/synapse';
import { define, trust, mix, prop } from '@dnajs/idom';
import { PageMixin } from '../../mixins/page.js';
import { SearchMixin } from '../../mixins/search.js';
import { __ } from '../../factories/i18n.js';
import GROUPS from '../../config/groups.js';
import { IS_KIDS } from '../../config/features.js';
import '../../components/groups-list/groups-list.js';
import '../../components/periodic-table/periodic-table.js';

export default class ClassesPage extends mix(PageViewComponent).with(PageMixin, SearchMixin) {
    /**
     * @inheritdoc
     */
    get template() {
        return () =>
            <table is="periodic-table"
                data-mode={IS_KIDS ? 'kids' : null}
                lang={this.lang}
                chemicalElements={this.chemicalElements}
                activeGroups={this.activeGroups}
                isElementDisabled={this.isElementDisabled}
                focusContent={() => {
                    if (!this.activeGroup) {
                        return <h3 class="description-category">{__('classes')}</h3>;
                    }
                    let title = __(GROUPS.find((group) => group.name === this.activeGroup).key);
                    let text = '';
                    if (!IS_KIDS) {
                        text = __(`${GROUPS.find((group) => group.name === this.activeGroup).key}_description`);
                    }
                    return [
                        <h3 class="description-category">{__('classes')}</h3>,
                        title && <h2 class="description-title">{trust(title)}</h2>,
                        text && <div class="description-text">{trust(text)}</div>,
                    ];
                }}
                toolsContent={() => <groups-list groups={this.groups} activeGroup={this.activeGroup} />}
            />;
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click .groups-list li button': this.onElementClick,
        };
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            activeGroup: prop(String).setter((newVal) => {
                if (IS_KIDS) {
                    // In Kids version, classes are simplified and grouped in few classes.
                    switch (newVal) {
                        case 'metal':
                        case 'metalc':
                        case 'metald':
                        case 'postmetal':
                            this.activeGroups = ['metal', 'metalc', 'metald', 'postmetal'];
                            return 'metal';
                        case 'nonmetal':
                        case 'alogeni':
                            this.activeGroups = ['nonmetal', 'alogeni'];
                            return 'nonmetal';
                    }
                }
                this.activeGroups = [newVal];
                return newVal;
            }),
            activeGroups: Array,
            activeElement: prop(Object).observe(() => {
                this.activeGroup = this.activeElement.group;
            }),
        };
    }

    /**
     * Get translated and sorted groups (ascending order).
     *
     * @readonly
     * @memberof ClassesPage
     */
    get groups() {
        return this.factory('data').getTranslatedGroups();
    }

    /**
     * @inheritdoc
     */
    onElementClick(ev, target) {
        let group = target.closest('[data-group]').dataset.group;
        if (group === 'unknown') {
            return;
        }
        this.activeGroup = group;
    }

    /**
     * @inheritdoc
     */
    isElementDisabled(element) {
        return element.group === 'unknown';
    }
}

define('classes-page', ClassesPage);