import { PageViewComponent } from '@chialab/synapse';
import { PageMixin } from '../../../mixins/page.js';
import { prop, mix, DOM } from '@dnajs/idom';
import toolsContentTemplate from './tools-content.jsx';
import '../../../components/periodic-table/periodic-table.js';

export default class ExerciseBasePage extends mix(PageViewComponent).with(PageMixin) {
    /**
     * @inheritdoc
     */
    async exec(lang, level) {
        const dataFactory = this.factory('data');
        this.chemicalElements = dataFactory.getData();
        const exercises = dataFactory.getExercises(lang);
        this.lang = lang;
        this.level = level;
        this.exercise = exercises.find((exercise) => exercise.name === this.name);

        if (this.level) {
            this.enabledGroups = this.exercise.levels[this.level].enabled_groups;
            this.enabledElements = this.getEnabledElements();
        }
    }

    /**
     * Return template for tools content.
     *
     * @readonly
     * @memberof ExerciseBasePage
     */
    get toolsContent() {
        return toolsContentTemplate;
    }

    /**
     * @inheritdoc
     */
    get template() {
        return () =>
            <table is="periodic-table"
                lang={this.lang}
                chemicalElements={this.chemicalElements}
                isElementDisabled={this.isElementDisabled.bind(this)}
                isElementCovered={this.isElementCovered.bind(this)}
                isElementMatched={this.isElementMatched.bind(this)}
                focusContent={this.focusContent.bind(this)}
                toolsContent={this.toolsContent.bind(this)}
            />;
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click .menu button.reset': this.resetAnswers,
        };
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            level: prop(String).attribute('data-level'),
            correctAnswers: prop(Number).default(0),
            wrongAnswers: prop(Number).default(0),
            enabledElements: prop(Array).default([]),
            matchedElements: prop(Array).default([]),
        };
    }

    /**
     * @inheritdoc
     */
    get name() {
        return '';
    }

    /**
     * Tells if skip feature is enabled.
     *
     * @readonly
     * @memberof ExerciseBasePage
     */
    get isSkipEnabled() {
        return this.exercise && this.exercise.skip_enabled && this.enabledElements.length != this.matchedElements.length;
    }

    /**
     * Return tries.
     *
     * @readonly
     * @memberof ExerciseBasePage
     */
    get tries() {
        return this.correctAnswers + this.wrongAnswers;
    }

    /**
     * Build URL to level choice.
     *
     * @readonly
     * @memberof ExerciseBasePage
     */
    get exitUrl() {
        const i18n = this.factory('i18n');
        return `/${i18n.currentLanguage}/training/${this.name}`;
    }

    /**
     * @inheritdoc
     */
    connectedCallback() {
        super.connectedCallback();
        this.node.classList.add('exercise-page');
    }

    /**
     * @inheritdoc
     */
    onElementClick(ev, target) {
        const element = DOM.getNodeComponent(target).chemicalElement;
        if (!this.level || this.isElementDisabled(element)) {
            return;
        }
        this.checkAnswer(element);
    }

    /**
     * Skip set of element's atomic number in hash.
     */
    setElementHash() { }

    /**
     * Get enabled elements for an exercise.
     *
     * @return {Array}
     * @memberof ExerciseBasePage
     */
    getEnabledElements() {
        if (!this.chemicalElements) {
            return [];
        }
        return this.chemicalElements.filter((el) => this.enabledGroups.includes(el.group));
    }

    /**
     * @inheritdoc
     */
    isElementDisabled(element) {
        if (!this.enabledGroups || !element) {
            return true;
        }
        return !this.enabledGroups.includes(element.group) || this.isElementMatched(element);
    }

    /**
     * Check if element is matched.
     *
     * @param {Object} element
     * @return {Boolean}
     * @memberof ExerciseBasePage
     */
    isElementMatched(element) {
        return this.matchedElements.some((el) => el.atomic_number == element.atomic_number);
    }

    /**
     * Determines if a table element has to be covered, showing no infos.
     *
     * @return {Boolean}
     * @memberof ExerciseBasePage
     */
    isElementCovered() { }

    /**
     * Handle element matching.
     *
     * @memberof ExerciseBasePage
     */
    onElementMatch() { }

    /**
     * Build current exercise's url with level.
     *
     * @param {string} level
     * @return {string}
     * @memberof ExerciseBasePage
     */
    getUrlFromLevel(level) {
        return `/${this.getContext().router.current}/${level}`;
    }

    /**
     * Check if answer is correct.
     *
     * @param {Object} element
     * @memberof ExerciseBasePage
     */
    checkAnswer(/* element */) { }

    /**
     * Reset answers, matched elements and unknown element.
     *
     * @memberof ExerciseBasePage
     */
    resetAnswers() {
        this.correctAnswers = 0;
        this.wrongAnswers = 0;
        this.matchedElements = [];
    }
}
