import { __ } from '../../factories/i18n.js';
import { IS_KIDS } from '../../config/features.js';
import { range } from '../../helpers/array.js';

function renderTableElement(element) {
    return <table-element
        lang={this.lang}
        chemicalElement={element}
        category={this.category}
        temperature={this.temperature}
        data-active={this.activeElement === element}
        data-active-group={this.activeGroups && this.activeGroups.includes(element.group)}
        data-highlight={this.highlightElements && this.highlightElements.includes(element)}
        data-importance={this.importanceMap && this.importanceMap[element.atomic_number - 1]}
        data-disabled={this.isElementDisabled && this.isElementDisabled(element)}
        data-covered={this.isElementCovered && this.isElementCovered(element)}
        data-matched={this.isElementMatched && this.isElementMatched(element)}
    />;
}

function renderTableRow(index) {
    return <td
        class="position-cell position-cell-v"
        data-highlight={this.highlightRows.includes(index)}
        data-active={this.activeRow === index}>{index}</td>;
}

this.chemicalElements && <tbody>
    <tr>
        {[
            <td class="position-cell-corner"></td>,
            range(18).map((_, index) => <td class="position-cell position-cell-h"
                data-highlight={this.highlightColumns.includes(index + 1)}
                data-active={this.activeColumn === (index + 1)}>{index + 1}</td>),
        ]}
    </tr>
    <tr>
        {renderTableRow.call(this, 1)}
        <td>{renderTableElement.call(this, this.chemicalElements[0])}</td>
        <td class="empty-element"></td>
        <td class="focus-content-cell" colspan="10" rowspan="3">
            {this.focusContent && <div class="focus-content-content">{this.focusContent.call(this)}</div>}
        </td>
        <td class="empty-element" colspan="5"></td>
        <td>{renderTableElement.call(this, this.chemicalElements[1])}</td>
    </tr>
    <tr>
        {renderTableRow.call(this, 2)}
        <td>{renderTableElement.call(this, this.chemicalElements[2])}</td>
        <td>{renderTableElement.call(this, this.chemicalElements[3])}</td>
        {this.chemicalElements.slice(4, 10).map((elem) => <td>{renderTableElement.call(this, elem)}</td>)}
    </tr>
    <tr>
        {renderTableRow.call(this, 3)}
        <td>{renderTableElement.call(this, this.chemicalElements[10])}</td>
        <td>{renderTableElement.call(this, this.chemicalElements[11])}</td>
        {this.chemicalElements.slice(12, 18).map((elem) => <td>{renderTableElement.call(this, elem)}</td>)}
    </tr>
    <tr>
        {renderTableRow.call(this, 4)}
        {this.chemicalElements.slice(18, 36).map((elem) => <td>{renderTableElement.call(this, elem)}</td>)}
    </tr>
    <tr>
        {renderTableRow.call(this, 5)}
        {this.chemicalElements.slice(36, 54).map((elem) => <td>{renderTableElement.call(this, elem)}</td>)}
    </tr>
    <tr>
        {renderTableRow.call(this, 6)}
        {this.chemicalElements.slice(54, 56).map((elem) => <td>
            {renderTableElement.call(this, elem, 6, 1)}
        </td>)}
        <td class="elements-separator-lanthanides">{renderTableElement.call(this, this.chemicalElements[56])}</td>
        {this.chemicalElements.slice(71, 86).map((elem) => <td>{renderTableElement.call(this, elem)}</td>)}
    </tr>
    <tr>
        {renderTableRow.call(this, 7)}
        {this.chemicalElements.slice(86, 88).map((elem) => <td>
            {renderTableElement.call(this, elem, 7, 1)}
        </td>)}
        <td class="elements-separator-actinides">{renderTableElement.call(this, this.chemicalElements[88])}</td>
        {this.chemicalElements.slice(103, 118).map((elem) => <td>{renderTableElement.call(this, elem)}</td>)}
    </tr>
    {/* secondary table */}
    <tr class="sections-separator" />
    <tr>
        <td></td>
        {[
            <td rowspan="2" colspan="3" class="logo">
                <div>
                    <h2>{__(IS_KIDS ? 'periodic table of elements kids' : 'periodic table of elements')}</h2>
                    <img src="assets/zanichelli.svg" />
                    <button class="credits-toggle">&copy; {new Date().getFullYear()} / Colophon</button>
                    <dialog class="credits" hidden>
                        <div class="dialog-wrapper">
                            <div class="dialog-content">
                                <p>Copyright © {new Date().getFullYear()} <a href="http://www.zanichelli.it" target="_blank">Zanichelli editore S.p.A.</a>, Bologna</p>
                                <p>
                                    <strong>Ideazione e redazione</strong>: Martina Mugnai, Beppe Chia, Manuel Zanettin, Edoardo Cavazza<br />
                                    <strong>Contributi redazionali</strong>: Andrea Castellani<br />
                                    <strong>Progetto generale, interfaccia grafica e sviluppo</strong>: <a href="http://www.chialab.it" target="_blank">Chialab</a><br />
                                    <strong>Redazione testi</strong>: Laura Moroni<br />
                                    <strong>Consulenza</strong>: Elisa Zini, Massimo Dellavalle<br />
                                    <strong>Aggiornamento</strong>: Claudio Dutto, Maurizio Mattioli, Dario Sacco, Paolo Cuffiani<br />
                                    <strong>Consulenza all'aggiornamento</strong>: Ilaria Cirigliano, Marco Coronelli, Mattia Gambetti, Giacomo Magnoni, Pietro Tellarini dell'Istituto d'istruzione Superiore F. Alberghetti di Imola<br />
                                    <strong>Tavola dell'abbondanza</strong>: Nicola Armaroli (supervisione), Paola Ruspantini (redazione)<br /><br />
                                    Si ringrazia la European Chemical Society per la gentile concessione <a href="https://www.euchems.eu/euchems-periodic-table/" target="_blank">(https://www.euchems.eu/euchems-periodic-table/)</a><br />
                                </p>
                            </div>
                            <div class="dialog-commands">
                                <button class="close-credits">{__('close')}</button>
                            </div>
                        </div>
                    </dialog>
                </div>
            </td>,
            this.chemicalElements.slice(57, 71).map((elem) => <td>{renderTableElement.call(this, elem)}</td>),
        ]}
    </tr>
    <tr>
        <td></td>
        {this.chemicalElements.slice(89, 103).map((elem) => <td>{renderTableElement.call(this, elem)}</td>)}
    </tr>
    <tr class="tools-content-row">
        <td class="tools-content-cell" colspan="19" rowspan="3">
            {this.toolsContent && <div class="tools-content-content">{this.toolsContent.call(this)}</div>}
        </td>
    </tr>
</tbody>;
