import { IS_KIDS } from './features.js';

export default {
    electronic_affinity: 'kJ/mol',
    density: 'g/L',
    melting_temperature: IS_KIDS ? '°C' : 'K',
    boiling_temperature: IS_KIDS ? '°C' : 'K',
    atomic_radius: 'pm',
    first_ionisation_energy: 'kJ/mol',
    atomic_mass: 'u',
    electronegativity: 'pauling scale',
};
