// In the Kids version, elements' some groups are simplified and grouped this way:
//  - metal --> metal, metalc, metald, postemetal
//  - nonmetal --> nonmetal, alogeni

const GROUPS_KIDS = [
    {
        key: 'actinides',
        name: 'attinidi',
    },
    {
        key: 'metal',
        name: 'metal',
    },
    {
        key: 'noble gases',
        name: 'gas',
    },
    {
        key: 'lanthanides',
        name: 'lantanidi',
    },
    {
        key: 'nonmetals',
        name: 'nonmetal',
    },
    {
        key: 'semimetal',
        name: 'semimetal',
    },
];

const GROUPS = [
    {
        key: 'actinides',
        name: 'attinidi',
    },
    {
        key: 'alkali metals',
        name: 'metal',
    },
    {
        key: 'alkaline earth metals',
        name: 'metalc',
    },
    {
        key: 'dblock transition metals',
        name: 'metald',
    },
    {
        key: 'halogens',
        name: 'alogeni',
    },
    {
        key: 'lanthanides',
        name: 'lantanidi',
    },
    {
        key: 'noble gases',
        name: 'gas',
    },
    {
        key: 'nonmetals',
        name: 'nonmetal',
    },
    {
        key: 'pblock transition metals',
        name: 'postmetal',
    },
    {
        key: 'semimetal',
        name: 'semimetal',
    },
];

/**
 * Map of translation key, data key for elements' group.
 */
export default process.env.IS_KIDS == 1 ? GROUPS_KIDS : GROUPS;