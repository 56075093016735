import { define, prop } from '@dnajs/idom';
import { Component } from '@chialab/synapse';
import { IS_KIDS } from '../../config/features.js';
import template from './tp-menu.jsx';

/**
 * List of translation keys for thematic tables subsections.
 */
const THEMATIC_SECTION_KIDS = [
    'atomic_mass',
    'classes',
    'density',
    'discovered in',
    'temperature and states',
    'element_scarcity',
];
const THEMATIC_SECTIONS_STANDARD = [
    ...THEMATIC_SECTION_KIDS,
    'atomic_radius',
    'electronegativity',
    'electronic_affinity',
    'electronic_configuration',
    'first_ionisation_energy',
    'oxidation_numbers',
];

const THEMATIC_SECTIONS = IS_KIDS ? THEMATIC_SECTION_KIDS : THEMATIC_SECTIONS_STANDARD;

export class TPMenuComponent extends Component {
    /**
     * @inheritdoc
     */
    get template() {
        return template;
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            lang: String,
            route: String,
            section: String,
            subsection: String,
            searchEnabled: Boolean,
            visible: prop(Boolean).attribute('data-visibile'),
        };
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click .burger-menu': () => {
                this.visible = !this.visible;
            },
            'click': (ev) => {
                if (ev.target.matches('input')) {
                    return;
                }
                let target = ev.target.closest('[data-toggle]:not([disabled])');
                if (target) {
                    let value = target.dataset.toggle;
                    if (value === 'open') {
                        target.setAttribute('data-toggle', '');
                    } else {
                        this.closeTogglers(target);
                        target.setAttribute('data-toggle', 'open');
                        let input = target.querySelector('input');
                        if (input) {
                            input.value = '';
                            input.focus();
                        }
                    }
                } else {
                    this.closeTogglers();
                }
            },
            'input [type=\'search\']': (ev) => {
                const searchText = ev.target.value;
                if (!searchText) {
                    this.factory('search').reset();
                    return;
                }
                this.factory('search').searchElement(searchText);
            },
            'search': (ev) => {
                const searchText = ev.target.value;
                if (!searchText) {
                    return;
                }
                let found = this.factory('search').searchElement(searchText, true);
                if (found.length) {
                    this.visible = false;
                    this.closeTogglers();
                }
            },
        };
    }

    /**
     * @inheritdoc
     */
    async initialize(...args) {
        await super.initialize(...args);
        const app = this.getContext();
        const i18n = this.factory('i18n');

        app.on('navigation', (route) => {
            this.visible = false;
            this.route = route;
            let routeChunks = route.split('/');
            if (!isNaN(routeChunks[routeChunks.length - 1])) {
                routeChunks.pop();
            }
            this.section = routeChunks[1];
            this.subsection = routeChunks[2];
            this.searchEnabled = this.section !== 'training';
        });

        this.lang = i18n.currentLanguage;
        i18n.on('change', (lang) => {
            this.lang = lang;
        });
    }

    /**
     * @inheritdoc
     */
    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('click', (ev) => {
            if (!this.node.contains(ev.target)) {
                this.visible = false;
            }
        });
    }

    /**
     * Generate a map of `label`, `name`, `url` for thematic tables subsections.
     * Labels are sorted by ascending order and based on actual language translation.
     * Urls are genereted by translation keys with a dashed form transformation.
     *
     * @memberof PeriodicTableApp
     */
    get thematicSections() {
        const i18n = this.factory('i18n');

        return THEMATIC_SECTIONS.map((section) => ({
            label: i18n.translate(section),
            name: section.replace(/[\s_]/g, '-'),
            // Replace whitespaces and `_` with `-`.
            url: `thematic-tables/${section.replace(/[\s_]/g, '-')}`,
        })).sort((a, b) => {
            const a1 = a.label.toLowerCase();
            const b1 = b.label.toLowerCase();
            if (a1 < b1) {
                return -1;
            } else if (a1 > b1) {
                return 1;
            }
            return 0;
        });
    }

    /**
     * Generate a map of `label`, `name`, `url` for training subsections.
     * Urls are genereted by translation keys with a dashed form transformation.
     *
     * @memberof PeriodicTableApp
     */
    get trainingSections() {
        const i18n = this.factory('i18n');
        let exercises = this.factory('data').getExercises(i18n.currentLanguage);

        if (IS_KIDS) {
            exercises = exercises.filter((exercise) => exercise.kids_enabled);
        }

        return exercises.map((exercise) => ({
            label: exercise.title,
            name: exercise.name,
            url: `training/${exercise.name}`,
        }));
    }

    /**
     * Close all togglers but `except`.
     *
     * @param {Node} except Toggler node to keep open.
     * @memberof TPMenuComponent
     */
    closeTogglers(except) {
        let inputs = Array.from(this.querySelectorAll('input'));
        inputs.forEach((input) => {
            input.blur();
        });

        let togglers = Array.from(this.querySelectorAll('[data-toggle]'));
        togglers.forEach((toggler) => {
            if (toggler !== except) {
                toggler.setAttribute('data-toggle', '');
            }
        });
    }

    /**
     * Change current route setting `lang`.
     *
     * @param {string} lang
     * @return {string}
     * @memberof TPMenuComponent
     */
    getCurrentRouteForLang(lang) {
        if (!this.route) {
            return null;
        }
        return `/${lang}/${this.route.split('/').slice(1).join('/')}`;
    }
}

define('tp-menu', TPMenuComponent, { extends: 'nav' });
