import AssociateElementBasePage from '../associate-element-base/associate-element-base.js';
import { define } from '@dnajs/idom';

export default class FindClassesPage extends AssociateElementBasePage {
    /**
     * @inheritdoc
     */
    get name() {
        return 'find-classes';
    }

    /**
     * @inheritdoc
     */
    get associationGroups() {
        if (!this.enabledGroups) {
            return [];
        }
        return this.factory('data').getTranslatedGroups().filter((group) => this.enabledGroups.includes(group.name));
    }
}

define('find-classes-page', FindClassesPage);