import ExerciseBasePage from '../exercise-base/exercise-base.js';
import { prop } from '@dnajs/idom';
import focusContentTemplate from './focus-content.jsx';

export default class SingleElementPage extends ExerciseBasePage {
    /**
     * @inheritdoc
     */
    async exec(lang, level) {
        await super.exec(lang, level);
        this.unknownElement = this.getRandomElement();
    }

    /**
     * Return template for focus content.
     *
     * @readonly
     * @memberof SingleElementPage
     */
    get focusContent() {
        return focusContentTemplate;
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click .skip-btn': this.skipElement,
        };
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            unknownElement: prop(Object),
        };
    }

    /**
     * @inheritdoc
     */
    get symbol() {
        return '';
    }

    /**
     * @inheritdoc
     */
    get title() {
        return '';
    }

    /**
     * Return a random element from those enabled.
     * Elements already correctly matched will be skipped.
     *
     * @return {Object}
     * @memberof SingleElementPage
     */
    getRandomElement() {
        let remaining = this.enabledElements.filter((element) => !this.matchedElements.includes(element));
        let randomInt = Math.floor(Math.random() * remaining.length);
        return remaining[randomInt];
    }

    /**
     * Add current unknown element to matched ones and pick another random element.
     *
     * @memberof SingleElementPage
     */
    onElementMatch() {
        this.matchedElements = this.matchedElements.concat(this.unknownElement);
        if (this.matchedElements.length !== this.enabledElements.length) {
            this.unknownElement = this.getRandomElement();
        } else {
            this.unknownElement = null;
        }
    }

    /**
     * @inheritdoc
     */
    checkAnswer(element) {
        if (!element) {
            return;
        }
        if (element.atomic_number == this.unknownElement.atomic_number) {
            this.correctAnswers += 1;
            this.onElementMatch();
        } else {
            this.wrongAnswers += 1;
        }
    }

    /**
     * Skip current unknown element adding 3 wrong answers.
     *
     * @memberof ArrangeElementsPage
     */
    skipElement() {
        this.wrongAnswers += 3;
        this.onElementMatch();
    }

    /**
     * @inheritdoc
     */
    resetAnswers() {
        super.resetAnswers();
        this.unknownElement = this.getRandomElement();
    }
}
