import '@chialab/ieb-icons';
import { trust } from '@dnajs/idom';

this.chemicalElement && [
    <div class="main-data">
        <header>
            <table-element key={this.chemicalElement.atomic_number} lang={this.lang} chemicalElement={this.chemicalElement} temperature={this.temperature} />
            {this.showPoster && <img title={this.chemicalElement.title[this.lang]} alt={this.chemicalElement.title[this.lang]} src={this.chemicalElement.poster.uri} />}
        </header>
        <div class="card-container">
            {this.content && this.content.call(this)}
            <footer>
                <button class="previous"><ieb-icon name="arrow-sx" /></button>
                <button class="next"><ieb-icon name="arrow-dx" /></button>
                <button class="close"><ieb-icon name="x" /></button>
                {this.showDescription && this.chemicalElement.description[this.lang] && <button class="description-btn"><ieb-icon name="info" /></button>}
            </footer>
        </div>
    </div>,
    this.showDescription && this.extended && <div class="description">
        <div class="description-scroller">{trust(this.chemicalElement.description[this.lang])}</div>
    </div>,
];
