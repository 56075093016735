import { prop, define } from '@dnajs/idom';
import { Component } from '@chialab/synapse';
import template from './configuration-graph.jsx';

export class ConfigurationGraph extends Component {
    /**
     * @inheritdoc
     */
    get template() {
        return template;
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            chemicalElement: prop(Object).observe(() => {
                const data = this.factory('data');
                if (this.chemicalElement) {
                    this.configuration = data.getConfigurationData(this.chemicalElement);
                } else {
                    this.configuration = null;
                }
            }),
            configuration: Object,
        };
    }

    get c() {
        return this.configuration;
    }
}

define('configuration-graph', ConfigurationGraph);
