import { PageViewComponent } from '@chialab/synapse';
import { trust, mix, define, DOM } from '@dnajs/idom';
import { __ } from '../../factories/i18n';
import { PageMixin } from '../../mixins/page.js';
import { SearchMixin } from '../../mixins/search.js';
import { toCelsius } from '../../helpers/temperature.js';
import { IS_KIDS } from '../../config/features.js';
import UNITS from '../../config/units.js';
import '../../components/periodic-table/periodic-table.js';

export default class ThematicPage extends mix(PageViewComponent).with(PageMixin, SearchMixin) {
    /**
     * @inheritdoc
     */
    async exec(lang, category, ...args) {
        await super.exec(lang, category, ...args);

        // Map url to chemical element property key.
        switch (category) {
            case 'discovered-in':
                this.category = 'discovery';
                break;
            default:
                this.category = category.replace(/-/g, '_');
                break;
        }
    }

    /**
     * @inheritdoc
     */
    get template() {

        // tavola dell'abbondanza
        if (this.category === 'element_scarcity') {
            return () =>
                <div class={`element-scarcity-${this.lang}`}></div>;
        }

        return () =>
            <table is="periodic-table"
                lang={this.lang}
                chemicalElements={this.chemicalElements}
                category={this.category}
                isElementDisabled={this.isElementDisabled.bind(this)}
                highlightElements={this.highlightElements}
                activeElement={this.activeElement}
                focusContent={this.focusContent}
                toolsContent={this.toolsContent}
            />;
    }

    /**
     * @inheritdoc
     */
    get focusContent() {
        return function() {
            let value;
            if (this.activeElement) {
                value = this.activeElement[this.category];
                if (this.category === 'atomic_mass' && this.activeElement.atomic_mass_estimated) {
                    value = `[${value}]`;
                }
                if (this.category === 'discovery' && this.activeElement.known_in_ancient_ages) {
                    value = __('known in ancient ages');
                }
                if (IS_KIDS && (this.category === 'melting_temperature' || this.category === 'boiling_temperature')) {
                    value = toCelsius(this.activeElement[this.category]);
                }
            }
            let unit = UNITS[this.category];
            return [
                <h3 class="description-category">{__(this.category)} {unit ? `(${__(unit)})` : ''}</h3>,
                !this.activeElement && (this.category === 'atomic_radius') && <p class="description-page">{__('atomic radius')}</p>,
                this.activeElement && <h2 class="description-title">{trust(this.activeElement.title[this.lang])}</h2>,
                this.activeElement && <div class="description-text">{trust(value != null ? value : '/')}</div>,
            ];
        };
    }

    /**
     * @inheritdoc
     */
    get toolsContent() {
        return null;
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            category: String,
        };
    }

    /**
     * @inheritdoc
     */
    onElementClick(ev, target) {
        const chemicalElement = DOM.getNodeComponent(target).chemicalElement;
        if (this.isElementDisabled(chemicalElement)) {
            return;
        }
        super.onElementClick(ev, target);
    }

    /**
     * Determines if a table element is disabled. ♿️
     *
     * An element with null value for `this.category` is disabled.
     * For `discovery` category, do not disable if `known_in_ancient_ages` is true.
     *
     * @param {Object} chemicalElement Chemical element model.
     * @return {Boolean}
     */
    isElementDisabled(chemicalElement) {
        if (!this.category || !chemicalElement) {
            return false;
        }

        if (chemicalElement[this.category] == null) {
            if (this.category === 'discovery' && chemicalElement.known_in_ancient_ages) {
                return false;
            }
            return true;
        }
    }
}

define('thematic-page', ThematicPage);
