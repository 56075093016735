import { BaseComponent, define } from '@dnajs/idom';
import template from './rotate-device.jsx';

export class RotateDevice extends BaseComponent {
    get template() {
        return template;
    }
}

define('rotate-device', RotateDevice, {
    extends: 'dialog',
});
