import '@chialab/ieb-icons';
import { __ } from '../../../factories/i18n';
import { IS_KIDS } from '../../../config/features.js';
import { range } from '../../../helpers/array.js';

[
    !this.level && this.exercise &&
    <div class="choose-level">
        <h2 class="type-name">{this.exercise.title}</h2>
        <div class="instructions">{this.exercise.instructions}</div>
        <div class="levels">
            {IS_KIDS ?
                <p><ieb-icon name="arrow-dx" /><a rel="nofollow" href={this.getUrlFromLevel('basic')}>{__('play')}</a></p> :
                Object.keys(this.exercise.levels).map((level) => <p><ieb-icon name="arrow-dx" /><a rel="nofollow" href={this.getUrlFromLevel(level)}>{this.exercise.levels[level].title}</a></p>)
            }
        </div>
    </div>,
    this.level && this.exercise &&
    <div class="report-container">
        <div class="report">
            <div class="indicator">
                <div class="tries">
                    <span>{this.tries || '0'}</span>
                    <label>{__('tries')}</label>
                </div>
                <div class="answers-label">
                    <div class="correct-label">
                        <span>{this.correctAnswers || '0'}</span>
                        <label>{__('correct')}</label>
                    </div>
                    <div class="wrong-label">
                        <span>{this.wrongAnswers || '0'}</span>
                        <label>{__('wrong')}</label>
                    </div>
                </div>
            </div>
            {range(this.correctAnswers).map(() => <div class="correct" style={`width: ${100 / this.tries}%`}></div>)}
            {range(this.wrongAnswers).map(() => <div class="wrong" style={`width: ${100 / this.tries}%`}></div>)}
        </div>
        <div class="menu">
            <div class="text-container">
                <p class="exercise-title">{this.exercise.title}</p>
                {this.isSkipEnabled && <p class="skip-message">{__('skip message')}</p>}
            </div>
            <div class="buttons">
                {this.isSkipEnabled && <button class="skip-btn">{__('skip')}</button>}
                <button class="reset">{__('reset')}</button>
                <a rel="nofollow" class="exit" href={this.exitUrl}>{__('exit')}</a>
            </div>
        </div>
    </div>,
];
