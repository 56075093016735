export const CardMixin = (SuperClass) => class extends SuperClass {
    /**
     * @inheritdoc
     */
    get events() {
        return {
            'element-card:previous': () => {
                const currentElement = this.activeElement;
                // Get previous element searching by atomic_number - 1.
                // Not found? Probably current element is the first one. Exploit Pac-Man effect.
                let io = this.chemicalElements.findIndex((el) => el.atomic_number == currentElement.atomic_number);
                let previousElements = this.chemicalElements.slice(0, io).reverse().concat(this.chemicalElements.slice(io + 1).reverse());
                let previous = previousElements.find((el) => !this.isElementDisabled(el));
                this.activeElement = previous;
            },
            'element-card:next': () => {
                const currentElement = this.activeElement;
                // Get next element searching by atomic_number + 1.
                // Not found? Probably current element is the last one. Exploit Pac-Man effect.
                let io = this.chemicalElements.findIndex((el) => el.atomic_number == currentElement.atomic_number);
                let nextElements = this.chemicalElements.slice(io + 1).concat(this.chemicalElements.slice(0, io));
                let next = nextElements.find((el) => !this.isElementDisabled(el));
                this.activeElement = next;
            },
            'element-card:close': () => {
                this.activeElement = null;
            },
            'click': (ev) => {
                if (ev.target.closest('.table-element, .element-card')) {
                    return;
                }
                this.activeElement = null;
            },
        };
    }
};
