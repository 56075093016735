import { PageViewComponent } from '@chialab/synapse';
import { mix, define, trust } from '@dnajs/idom';
import { PageMixin } from '../../mixins/page.js';
import { CardMixin } from '../../mixins/card.js';
import { SearchMixin } from '../../mixins/search.js';
import { __ } from '../../factories/i18n.js';
import { IS_KIDS } from '../../config/features.js';
import UNITS from '../../config/units.js';
import { toCelsius } from '../../helpers/temperature.js';
import '../../components/periodic-table/periodic-table.js';

export default class TablePage extends mix(PageViewComponent).with(PageMixin, CardMixin, SearchMixin) {
    /**
     * @inheritdoc
     */
    get template() {
        return () => [
            <table is="periodic-table"
                lang={this.lang}
                chemicalElements={this.chemicalElements}
                highlightElements={this.highlightElements}
                activeElement={this.activeElement}
            />,
            <element-card
                lang={this.lang}
                temperature={this.temperature}
                hidden={!this.activeElement}
                extended={false}
                chemicalElement={this.activeElement}
                content={function() {
                    return <section class="data">
                        {this.chemicalElement.poster.description[this.lang] && <p class="poster-description">{trust(this.chemicalElement.poster.description[this.lang])}</p>}
                        {!IS_KIDS &&
                            <div class="class-group">
                                <label>{__('class')}:</label>
                                <span>{this.chemicalElement.group && this.chemicalElement.group !== 'unknown' ? trust((() => {
                                    switch (this.chemicalElement.group) {
                                        case 'semimetal': {
                                            return __('semimetal');
                                        }
                                        case 'nonmetal': {
                                            return __('nonmetals');
                                        }
                                        case 'alogeni': {
                                            return __('halogens');
                                        }
                                        case 'gas': {
                                            return __('noble gases');
                                        }
                                        case 'metal': {
                                            return __('alkali metals');
                                        }
                                        case 'metalc': {
                                            return __('alkaline earth metals');
                                        }
                                        case 'metald': {
                                            return __('dblock transition metals');
                                        }
                                        case 'postmetal': {
                                            return __('pblock transition metals');
                                        }
                                        case 'lantanidi': {
                                            return __('lanthanides');
                                        }
                                        case 'attinidi': {
                                            return __('actinides');
                                        }
                                    }
                                })()) : '/'}</span>
                            </div>}
                        <div class="atomic-mass">
                            <label>{__('atomic_mass')}:</label>
                            <span>{this.chemicalElement.atomic_mass != null ?
                                `${this.chemicalElement.atomic_mass_estimated ? `[${this.chemicalElement.atomic_mass}]` : this.chemicalElement.atomic_mass} ${UNITS['atomic_mass']}` :
                                '/'}</span>
                            <a class="thematic-link" href={`${this.lang}/thematic-tables/atomic-mass/${this.chemicalElement.atomic_number}`}>
                                <ieb-icon name="arrow-dx" />
                            </a>
                        </div>
                        {!IS_KIDS &&
                            <div class="oxidation-numbers">
                                <label>{__('oxidation_numbers')}:</label>
                                <span>{this.chemicalElement.oxidation_numbers != null ? this.chemicalElement.oxidation_numbers : '/'}</span>
                                <a class="thematic-link" href={`${this.lang}/thematic-tables/oxidation-numbers/${this.chemicalElement.atomic_number}`}>
                                    <ieb-icon name="arrow-dx" />
                                </a>
                            </div>}
                        {!IS_KIDS &&
                            <div class="electronic-configuration">
                                <label>{__('electronic_configuration')}:</label>
                                <span>{trust(this.chemicalElement.electronic_configuration ? this.chemicalElement.electronic_configuration : '/')}</span>
                                <a class="thematic-link" href={`${this.lang}/thematic-tables/electronic-configuration/${this.chemicalElement.atomic_number}`}>
                                    <ieb-icon name="arrow-dx" />
                                </a>
                            </div>}
                        <div class="melting-temperature">
                            <label>{__('melting_temperature')}:</label>
                            <span>{this.chemicalElement.melting_temperature != null ? `${IS_KIDS ? toCelsius(this.chemicalElement.melting_temperature) : this.chemicalElement.melting_temperature} ${UNITS['melting_temperature']}` : '/'}</span>
                            <a class="thematic-link" href={`${this.lang}/thematic-tables/melting-temperature/${this.chemicalElement.atomic_number}`}>
                                <ieb-icon name="arrow-dx" />
                            </a>
                        </div>
                        <div class="boiling-temperature">
                            <label>{__('boiling_temperature')}:</label>
                            <span>{this.chemicalElement.boiling_temperature != null ? `${IS_KIDS ? toCelsius(this.chemicalElement.boiling_temperature) : this.chemicalElement.boiling_temperature} ${UNITS['boiling_temperature']}` : '/'}</span>
                            <a class="thematic-link" href={`${this.lang}/thematic-tables/boiling-temperature/${this.chemicalElement.atomic_number}`}>
                                <ieb-icon name="arrow-dx" />
                            </a>
                        </div>
                        {!IS_KIDS &&
                            <div class="atomic-radius">
                                <label>{__('atomic_radius')}:</label>
                                <span>{this.chemicalElement.atomic_radius != null ? `${this.chemicalElement.atomic_radius} ${UNITS['atomic_radius']}` : '/'}</span>
                                <a class="thematic-link" href={`${this.lang}/thematic-tables/atomic-radius/${this.chemicalElement.atomic_number}`}>
                                    <ieb-icon name="arrow-dx" />
                                </a>
                            </div>}
                        {!IS_KIDS &&
                            <div class="first-ionisation-energy">
                                <label>{__('first_ionisation_energy')}:</label>
                                <span>{this.chemicalElement.first_ionisation_energy != null ? `${this.chemicalElement.first_ionisation_energy} ${UNITS['first_ionisation_energy']}` : '/'}</span>
                                <a class="thematic-link" href={`${this.lang}/thematic-tables/first-ionisation-energy/${this.chemicalElement.atomic_number}`}>
                                    <ieb-icon name="arrow-dx" />
                                </a>
                            </div>}
                        {!IS_KIDS &&
                            <div class="electronegativity">
                                <label>{__('electronegativity')}:</label>
                                <span>{this.chemicalElement.electronegativity != null ? `${this.chemicalElement.electronegativity} ${__(UNITS['electronegativity'])}` : '/'}</span>
                                <a class="thematic-link" href={`${this.lang}/thematic-tables/electronegativity/${this.chemicalElement.atomic_number}`}>
                                    <ieb-icon name="arrow-dx" />
                                </a>
                            </div>}
                        {!IS_KIDS &&
                            <div class="electronic-affinity">
                                <label>{__('electronic_affinity')}:</label>
                                <span>{this.chemicalElement.electronic_affinity != null ? `${this.chemicalElement.electronic_affinity} ${UNITS['electronic_affinity']}` : '/'}</span>
                                <a class="thematic-link" href={`${this.lang}/thematic-tables/electronic-affinity/${this.chemicalElement.atomic_number}`}>
                                    <ieb-icon name="arrow-dx" />
                                </a>
                            </div>}
                        <div class="density">
                            <label>{__('density')}:</label>
                            <span>{this.chemicalElement.density != null ? `${this.chemicalElement.density} ${UNITS['density']}` : '/'}</span>
                            <a class="thematic-link" href={`${this.lang}/thematic-tables/density/${this.chemicalElement.atomic_number}`}>
                                <ieb-icon name="arrow-dx" />
                            </a>
                        </div>
                        <div class="discovery">
                            <label>{__('discovery')}:</label>
                            <span>
                                {this.chemicalElement.discovery}
                                {!this.chemicalElement.discovery && (this.chemicalElement.known_in_ancient_ages ? __('known in ancient ages') : '/')}
                            </span>
                            <a class="thematic-link" href={`${this.lang}/thematic-tables/discovery/${this.chemicalElement.atomic_number}`}>
                                <ieb-icon name="arrow-dx" />
                            </a>
                        </div>
                    </section>;
                }}
            />,
        ];
    }
}

define('table-page', TablePage);
