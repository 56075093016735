import '@chialab/ieb-icons';
import { __ } from '../../factories/i18n.js';
import { IS_KIDS } from '../../config/features.js';

[
    <button class="burger-menu"><ieb-icon name="menu" /></button>,
    <ul class="main-menu">
        <li class={!this.section ? 'active' : null}><a href={`/${this.lang}/`}>{__('table')}</a></li>
        <li data-toggle class={this.section === 'thematic-tables' ? 'active' : null}>
            <a>{__('thematic tables')}</a>
            {this.thematicSections &&
                <div class="categories-box submenu">
                    <ul>{this.thematicSections.map((section) =>
                        <li>
                            <a class={this.subsection === section.name ? 'active' : null} href={`/${this.lang}/${section.url}/`}>{section.label}</a>
                        </li>)}
                    </ul>
                </div>}
        </li>
        <li data-toggle class={this.section === 'training' ? 'active' : null}>
            <a>{__('training')}</a>
            {this.trainingSections &&
                <div class="trainings-list submenu">
                    <ul>{this.trainingSections.map((section) =>
                        <li>
                            <a class={this.subsection === section.name ? 'active' : null} href={`/${this.lang}/${section.url}`}>{section.label}</a>
                        </li>)}
                    </ul>
                </div>}
        </li>
        <li class="search" data-toggle disabled={!this.searchEnabled}>
            <a><ieb-icon name="search" />{__('search')}</a>
            <div class="search-box submenu">
                <input type="search" placeholder={__('search an element')} autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />
            </div>
        </li>
        {!IS_KIDS && <li><a rel="nofollow" target="_system" download="zanichelli_tavola_periodica.pdf" href="assets/zanichelli_tavola_periodica.pdf"><ieb-icon name="download-here" />PDF</a></li>}
        {!IS_KIDS && <li class={this.lang === 'it' ? 'active' : null} style="margin-left: auto;"><a rel="nofollow" href={this.getCurrentRouteForLang('it')}>ITA</a></li>}
        {!IS_KIDS && <li class={this.lang === 'en' ? 'active' : null}><a rel="nofollow" href={this.getCurrentRouteForLang('en')}>ENG</a></li>}
    </ul>,
];
