import { BaseComponent, define, prop } from '@dnajs/idom';
import template from './native-apps-dialog.jsx';

export class NativeAppsDialog extends BaseComponent {
    get template() {
        return template;
    }

    get storageKey() {
        if (!window.localStorage) {
            return null;
        }
        if (!this.storage) {
            return null;
        }
        let key = this.storage;
        if (typeof key === 'boolean') {
            key = 'show.nativeappsdialog';
        }
        return key;
    }

    get properties() {
        return {
            storage: prop([String, Boolean])
                .observe(() => {
                    let key = this.storageKey;
                    if (key) {
                        let value = localStorage.getItem(key);
                        if (value) {
                            this.hidden = true;
                        }
                    }
                }),
            hidden: prop(Boolean).default(!window.localStorage).attribute(),
        };
    }

    get events() {
        return {
            'click button': (ev) => {
                ev.stopPropagation();
                this.hidden = true;
                let key = this.storageKey;
                if (key) {
                    localStorage.setItem(key, '1');
                }
            },
            'click': (ev) => {
                if (ev.target.closest('.dialog-wrapper')) {
                    return;
                }
                this.hidden = true;
            },
        };
    }
}

define('native-apps-dialog', NativeAppsDialog, {
    extends: 'dialog',
});
