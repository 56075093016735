import { define } from '@dnajs/idom';
import SingleElementPage from '../single-element/single-element.js';

export default class MatchSymbolsPage extends SingleElementPage {
    /**
     * @inheritdoc
     */
    get name() {
        return 'match-symbols';
    }

    /**
     * @inheritdoc
     */
    get symbol() {
        return '?';
    }

    /**
     * @inheritdoc
     */
    get title() {
        return this.unknownElement.title[this.lang];
    }
}

define('match-symbols-page', MatchSymbolsPage);
