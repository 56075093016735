import SingleElementPage from '../single-element/single-element.js';
import { define } from '@dnajs/idom';

export default class ArrangeElementsPage extends SingleElementPage {
    /**
     * @inheritdoc
     */
    get name() {
        return 'arrange-elements';
    }

    /**
     * @inheritdoc
     */
    get symbol() {
        return this.unknownElement.symbol;
    }

    /**
     * @inheritdoc
     */
    get title() {
        return this.unknownElement.title[this.lang];
    }

    /**
     * Determines if a table element has to be covered showing no infos.
     * It will be covered if it's enabled but not already matched.
     *
     * @memberof ArrangeElementsPage
     */
    isElementCovered(element) {
        return this.enabledElements.some((enabledElem) => enabledElem.atomic_number == element.atomic_number) &&
            !this.matchedElements.some((matchedElem) => matchedElem.atomic_number == element.atomic_number);
    }
}

define('arrange-elements-page', ArrangeElementsPage);
