import LEVELS from '../../config/levels.js';
import { range } from '../../helpers/array.js';

function getTransformation(index, type, level) {
    let max = LEVELS[level - 1][type];
    let sum = 0;
    if (index % 2 === 1) {
        index -= 1;
        sum = 180;
    }
    let angle = 360 / max;
    switch (type) {
        case 'p':
            sum -= 6;
            break;
        case 'd':
            sum -= 12;
            break;
        case 'f':
            sum -= 18;
            break;
    }
    return {
        transform: `rotate(${angle * index / 2 + sum}deg)`,
        webkitTransform: `rotate(${angle * index / 2 + sum}deg)`,
    };
}

function getNeutronTransformation(index, length) {
    let z = length - index;
    let origin = 0;
    let angle = 360;
    let sum = 0;
    if (index > 0) {
        if (index < 1 + 6) {
            index -= 1;
            angle /= 6;
            origin = 80;
        } else if (index < (1 + 6 + 18)) {
            index -= 1 + 6;
            angle /= 18;
            origin = 120;
            sum = 90;
        } else if (index < (1 + 6 + 18 + 36)) {
            index -= 1 + 6 + 18;
            angle /= 36;
            origin = 160;
        }
        else if (index < (1 + 6 + 18 + 36 + 54)) {
            index -= 1 + 6 + 18 + 36;
            angle /= 54;
            origin = 200;
        }
    }
    sum += 90 * (index % 4);
    index -= index % 4;
    return {
        transformOrigin: `50% ${origin}%`,
        transform: `translateY(-${origin * 0.75}%) rotate(${angle * index / 2 + sum}deg)`,
        zIndex: z,
    };
}

this.configuration && [
    <div>{LEVELS.map((config, level) =>
        Object.keys(config).map((type) => <div class="configuration-type" data-type={type} data-level={level + 1} hidden={!this.configuration[type] || !this.configuration[type][level + 1]}></div>)
    )}</div>,
    <div>
        <div class="configuration-electron" data-type="s" data-level="1" style={getTransformation(0, 's', 1)} hidden={!this.configuration.s || !this.configuration.s[1] || this.configuration.s[1] < 1} />
        <div class="configuration-electron" data-type="s" data-level="1" style={getTransformation(1, 's', 1)} hidden={!this.configuration.s || !this.configuration.s[1] || this.configuration.s[1] < 2} />
        <div class="configuration-electron" data-type="s" data-level="2" style={getTransformation(0, 's', 2)} hidden={!this.configuration.s || !this.configuration.s[2] || this.configuration.s[2] < 1} />
        <div class="configuration-electron" data-type="s" data-level="2" style={getTransformation(1, 's', 2)} hidden={!this.configuration.s || !this.configuration.s[2] || this.configuration.s[2] < 2} />
        <div class="configuration-electron" data-type="s" data-level="3" style={getTransformation(0, 's', 3)} hidden={!this.configuration.s || !this.configuration.s[3] || this.configuration.s[3] < 1} />
        <div class="configuration-electron" data-type="s" data-level="3" style={getTransformation(1, 's', 3)} hidden={!this.configuration.s || !this.configuration.s[3] || this.configuration.s[3] < 2} />
        <div class="configuration-electron" data-type="s" data-level="4" style={getTransformation(0, 's', 4)} hidden={!this.configuration.s || !this.configuration.s[4] || this.configuration.s[4] < 1} />
        <div class="configuration-electron" data-type="s" data-level="4" style={getTransformation(1, 's', 4)} hidden={this.chemicalElement.atomic_number < 30} />
        <div class="configuration-electron" data-type="s" data-level="5" style={getTransformation(0, 's', 5)} hidden={this.chemicalElement.atomic_number < 47} />
        <div class="configuration-electron" data-type="s" data-level="5" style={getTransformation(1, 's', 5)} hidden={this.chemicalElement.atomic_number < 48} />
        <div class="configuration-electron" data-type="s" data-level="6" style={getTransformation(0, 's', 6)} hidden={!this.configuration.s || !this.configuration.s[6] || this.configuration.s[6] < 1} />
        <div class="configuration-electron" data-type="s" data-level="6" style={getTransformation(1, 's', 6)} hidden={this.chemicalElement.atomic_number < 80} />
        <div class="configuration-electron" data-type="s" data-level="7" style={getTransformation(0, 's', 7)} hidden={!this.configuration.s || !this.configuration.s[7] || this.configuration.s[7] < 1} />
        <div class="configuration-electron" data-type="s" data-level="7" style={getTransformation(1, 's', 7)} hidden={!this.configuration.s || !this.configuration.s[7] || this.configuration.s[7] < 2} />

        <div class="configuration-electron" data-type="p" data-level="2" style={getTransformation(0, 'p', 2)} hidden={!this.configuration.p || !this.configuration.p[2] || this.configuration.p[2] < 1} />
        <div class="configuration-electron" data-type="p" data-level="2" style={getTransformation(1, 'p', 2)} hidden={!this.configuration.p || !this.configuration.p[2] || this.configuration.p[2] < 2} />
        <div class="configuration-electron" data-type="p" data-level="2" style={getTransformation(2, 'p', 2)} hidden={!this.configuration.p || !this.configuration.p[2] || this.configuration.p[2] < 3} />
        <div class="configuration-electron" data-type="p" data-level="2" style={getTransformation(3, 'p', 2)} hidden={!this.configuration.p || !this.configuration.p[2] || this.configuration.p[2] < 4} />
        <div class="configuration-electron" data-type="p" data-level="2" style={getTransformation(4, 'p', 2)} hidden={!this.configuration.p || !this.configuration.p[2] || this.configuration.p[2] < 5} />
        <div class="configuration-electron" data-type="p" data-level="2" style={getTransformation(5, 'p', 2)} hidden={!this.configuration.p || !this.configuration.p[2] || this.configuration.p[2] < 6} />
        <div class="configuration-electron" data-type="p" data-level="3" style={getTransformation(0, 'p', 3)} hidden={!this.configuration.p || !this.configuration.p[3] || this.configuration.p[3] < 1} />
        <div class="configuration-electron" data-type="p" data-level="3" style={getTransformation(1, 'p', 3)} hidden={!this.configuration.p || !this.configuration.p[3] || this.configuration.p[3] < 2} />
        <div class="configuration-electron" data-type="p" data-level="3" style={getTransformation(2, 'p', 3)} hidden={!this.configuration.p || !this.configuration.p[3] || this.configuration.p[3] < 3} />
        <div class="configuration-electron" data-type="p" data-level="3" style={getTransformation(3, 'p', 3)} hidden={!this.configuration.p || !this.configuration.p[3] || this.configuration.p[3] < 4} />
        <div class="configuration-electron" data-type="p" data-level="3" style={getTransformation(4, 'p', 3)} hidden={!this.configuration.p || !this.configuration.p[3] || this.configuration.p[3] < 5} />
        <div class="configuration-electron" data-type="p" data-level="3" style={getTransformation(5, 'p', 3)} hidden={!this.configuration.p || !this.configuration.p[3] || this.configuration.p[3] < 6} />
        <div class="configuration-electron" data-type="p" data-level="4" style={getTransformation(0, 'p', 4)} hidden={!this.configuration.p || !this.configuration.p[4] || this.configuration.p[4] < 1} />
        <div class="configuration-electron" data-type="p" data-level="4" style={getTransformation(1, 'p', 4)} hidden={!this.configuration.p || !this.configuration.p[4] || this.configuration.p[4] < 2} />
        <div class="configuration-electron" data-type="p" data-level="4" style={getTransformation(2, 'p', 4)} hidden={!this.configuration.p || !this.configuration.p[4] || this.configuration.p[4] < 3} />
        <div class="configuration-electron" data-type="p" data-level="4" style={getTransformation(3, 'p', 4)} hidden={!this.configuration.p || !this.configuration.p[4] || this.configuration.p[4] < 4} />
        <div class="configuration-electron" data-type="p" data-level="4" style={getTransformation(4, 'p', 4)} hidden={!this.configuration.p || !this.configuration.p[4] || this.configuration.p[4] < 5} />
        <div class="configuration-electron" data-type="p" data-level="4" style={getTransformation(5, 'p', 4)} hidden={!this.configuration.p || !this.configuration.p[4] || this.configuration.p[4] < 6} />
        <div class="configuration-electron" data-type="p" data-level="5" style={getTransformation(0, 'p', 5)} hidden={!this.configuration.p || !this.configuration.p[5] || this.configuration.p[5] < 1} />
        <div class="configuration-electron" data-type="p" data-level="5" style={getTransformation(1, 'p', 5)} hidden={!this.configuration.p || !this.configuration.p[5] || this.configuration.p[5] < 2} />
        <div class="configuration-electron" data-type="p" data-level="5" style={getTransformation(2, 'p', 5)} hidden={!this.configuration.p || !this.configuration.p[5] || this.configuration.p[5] < 3} />
        <div class="configuration-electron" data-type="p" data-level="5" style={getTransformation(3, 'p', 5)} hidden={!this.configuration.p || !this.configuration.p[5] || this.configuration.p[5] < 4} />
        <div class="configuration-electron" data-type="p" data-level="5" style={getTransformation(4, 'p', 5)} hidden={!this.configuration.p || !this.configuration.p[5] || this.configuration.p[5] < 5} />
        <div class="configuration-electron" data-type="p" data-level="5" style={getTransformation(5, 'p', 5)} hidden={!this.configuration.p || !this.configuration.p[5] || this.configuration.p[5] < 6} />
        <div class="configuration-electron" data-type="p" data-level="6" style={getTransformation(0, 'p', 6)} hidden={!this.configuration.p || !this.configuration.p[6] || this.configuration.p[6] < 1} />
        <div class="configuration-electron" data-type="p" data-level="6" style={getTransformation(1, 'p', 6)} hidden={!this.configuration.p || !this.configuration.p[6] || this.configuration.p[6] < 2} />
        <div class="configuration-electron" data-type="p" data-level="6" style={getTransformation(2, 'p', 6)} hidden={!this.configuration.p || !this.configuration.p[6] || this.configuration.p[6] < 3} />
        <div class="configuration-electron" data-type="p" data-level="6" style={getTransformation(3, 'p', 6)} hidden={!this.configuration.p || !this.configuration.p[6] || this.configuration.p[6] < 4} />
        <div class="configuration-electron" data-type="p" data-level="6" style={getTransformation(4, 'p', 6)} hidden={!this.configuration.p || !this.configuration.p[6] || this.configuration.p[6] < 5} />
        <div class="configuration-electron" data-type="p" data-level="6" style={getTransformation(5, 'p', 6)} hidden={!this.configuration.p || !this.configuration.p[6] || this.configuration.p[6] < 6} />

        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(0, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 1} />
        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(1, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 2} />
        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(2, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 3} />
        {/* <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(3, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 4} /> */}
        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(4, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 5} />
        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(5, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 6} />
        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(6, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 7} />
        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(7, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 8} />
        <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(8, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 9} />
        {/* <div class="configuration-electron" data-type="d" data-level="3" style={getTransformation(9, 'd', 3)} hidden={!this.configuration.d || !this.configuration.d[3] || this.configuration.d[3] < 10} /> */}

        <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(0, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 1} />
        <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(1, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 2} />
        <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(2, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 3} />
        {/* <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(3, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 4} /> */}
        <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(4, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 5} />
        <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(5, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 6} />
        {/* <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(6, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 7} /> */}
        <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(7, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 8} />
        <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(8, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 9} />
        {/* <div class="configuration-electron" data-type="d" data-level="4" style={getTransformation(9, 'd', 4)} hidden={!this.configuration.d || !this.configuration.d[4] || this.configuration.d[4] < 10} /> */}
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(0, 'd', 5)} hidden={this.chemicalElement.atomic_number < 71} />
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(1, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 2} />
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(2, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 3} />
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(3, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 4} />
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(4, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 5} />
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(5, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 6} />
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(6, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 7} />
        {/* <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(7, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 8} /> */}
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(8, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 9} />
        <div class="configuration-electron" data-type="d" data-level="5" style={getTransformation(9, 'd', 5)} hidden={!this.configuration.d || !this.configuration.d[5] || this.configuration.d[5] < 10} />
        <div class="configuration-electron" data-type="d" data-level="6" style={getTransformation(0, 'd', 6)} hidden={this.chemicalElement.atomic_number < 103} />
        <div class="configuration-electron" data-type="d" data-level="6" style={getTransformation(1, 'd', 6)} hidden={this.chemicalElement.atomic_number < 104} />
        <div class="configuration-electron" data-type="d" data-level="6" style={getTransformation(2, 'd', 6)} hidden={!this.configuration.d || !this.configuration.d[6] || this.configuration.d[6] < 3} />
        <div class="configuration-electron" data-type="d" data-level="6" style={getTransformation(3, 'd', 6)} hidden={!this.configuration.d || !this.configuration.d[6] || this.configuration.d[6] < 4} />
        <div class="configuration-electron" data-type="d" data-level="6" style={getTransformation(4, 'd', 6)} hidden={!this.configuration.d || !this.configuration.d[6] || this.configuration.d[6] < 5} />
        <div class="configuration-electron" data-type="d" data-level="6" style={getTransformation(5, 'd', 6)} hidden={!this.configuration.d || !this.configuration.d[6] || this.configuration.d[6] < 6} />
        <div class="configuration-electron" data-type="d" data-level="6" style={getTransformation(6, 'd', 6)} hidden={!this.configuration.d || !this.configuration.d[6] || this.configuration.d[6] < 7} />

        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(0, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 1} />
        {/* <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(1, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 2} /> */}
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(2, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 3} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(3, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 4} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(4, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 5} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(5, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 6} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(6, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 7} />
        {/* <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(7, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 8} /> */}
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(8, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 9} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(9, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 10} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(10, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 11} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(11, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 12} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(12, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 13} />
        <div class="configuration-electron" data-type="f" data-level="4" style={getTransformation(13, 'f', 4)} hidden={!this.configuration.f || !this.configuration.f[4] || this.configuration.f[4] < 14} />
        {/* <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(0, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 1} /> */}
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(1, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 2} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(2, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 3} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(3, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 4} />
        {/* <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(4, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 5} /> */}
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(5, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 6} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(6, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 7} />
        {/* <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(7, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 8} /> */}
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(8, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 9} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(9, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 10} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(10, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 11} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(11, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 12} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(12, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 13} />
        <div class="configuration-electron" data-type="f" data-level="5" style={getTransformation(13, 'f', 5)} hidden={!this.configuration.f || !this.configuration.f[5] || this.configuration.f[5] < 14} />

        {/* UNSTABLE */}
        <div class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 24 ? 's' : 'd'}
            data-level={this.chemicalElement.atomic_number < 24 ? 4 : 3}
            style={this.chemicalElement.atomic_number < 24 ? getTransformation(1, 's', 4) : getTransformation(3, 'd', 3)}
            hidden={this.chemicalElement.atomic_number < 20} />
        <div class="configuration-electron"
            data-type={this.chemicalElement.atomic_number > 24 && this.chemicalElement.atomic_number < 29 ? 's' : 'd'}
            data-level={this.chemicalElement.atomic_number > 24 && this.chemicalElement.atomic_number < 29 ? 4 : 3}
            style={this.chemicalElement.atomic_number > 24 && this.chemicalElement.atomic_number < 29 ? getTransformation(1, 's', 4) : getTransformation(9, 'd', 3)}
            hidden={this.chemicalElement.atomic_number <= 24} />

        <div class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 46 ? 's' : 'd'}
            data-level={this.chemicalElement.atomic_number < 46 ? 5 : 4}
            style={this.chemicalElement.atomic_number < 46 ? getTransformation(0, 's', 5) : getTransformation(9, 'd', 4)}
            hidden={this.chemicalElement.atomic_number < 37} />
        <div class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 41 ? 's' : 'd'}
            data-level={this.chemicalElement.atomic_number < 41 ? 5 : 4}
            style={this.chemicalElement.atomic_number < 41 ? getTransformation(1, 's', 5) : getTransformation(3, 'd', 4)}
            hidden={this.chemicalElement.atomic_number < 38} />
        <div class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 44 ? 's' : 'd'}
            data-level={this.chemicalElement.atomic_number < 44 ? 5 : 4}
            style={this.chemicalElement.atomic_number < 44 ? getTransformation(1, 's', 5) : getTransformation(6, 'd', 4)}
            hidden={this.chemicalElement.atomic_number < 43} />

        <div class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 78 ? 's' : 'd'}
            data-level={this.chemicalElement.atomic_number < 78 ? 6 : 5}
            style={this.chemicalElement.atomic_number < 78 ? getTransformation(1, 's', 6) : getTransformation(7, 'd', 5)}
            hidden={this.chemicalElement.atomic_number < 56} />

        <div
            class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 59 ? 'd' : 'f'}
            data-level={this.chemicalElement.atomic_number < 59 ? 5 : 4}
            style={this.chemicalElement.atomic_number < 59 ? getTransformation(0, 'd', 5) : getTransformation(1, 'f', 4)}
            hidden={this.chemicalElement.atomic_number < 57} />
        <div
            class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 65 ? 'd' : 'f'}
            data-level={this.chemicalElement.atomic_number < 65 ? 5 : 4}
            style={this.chemicalElement.atomic_number < 65 ? getTransformation(0, 'd', 5) : getTransformation(7, 'f', 4)}
            hidden={this.chemicalElement.atomic_number < 64} />

        <div
            class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 94 ? 'd' : 'f'}
            data-level={this.chemicalElement.atomic_number < 94 ? 6 : 5}
            style={this.chemicalElement.atomic_number < 94 ? getTransformation(0, 'd', 6) : getTransformation(4, 'f', 5)}
            hidden={this.chemicalElement.atomic_number < 89} />
        <div
            class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 91 ? 'd' : 'f'}
            data-level={this.chemicalElement.atomic_number < 91 ? 6 : 5}
            style={this.chemicalElement.atomic_number < 91 ? getTransformation(1, 'd', 6) : getTransformation(0, 'f', 5)}
            hidden={this.chemicalElement.atomic_number < 90} />
        <div
            class="configuration-electron"
            data-type={this.chemicalElement.atomic_number < 97 ? 'd' : 'f'}
            data-level={this.chemicalElement.atomic_number < 97 ? 6 : 5}
            style={this.chemicalElement.atomic_number < 97 ? getTransformation(0, 'd', 6) : getTransformation(7, 'f', 5)}
            hidden={this.chemicalElement.atomic_number < 96} />
    </div>,
    <div>{
        range(Object.keys(this.configuration)
            .reduce((count, type) => {
                count += Object.keys(this.configuration[type])
                    .reduce((subcount, level) => {
                        subcount += this.configuration[type][level];
                        return subcount;
                    }, 0);
                return count;
            }, 0)
        ).map((_, index, arr) => <div class="configuration-neutron" style={getNeutronTransformation(index, arr.length)} />)
    }</div>,
];
