import { BaseComponent, define } from '@dnajs/idom';
import template from './groups-list.jsx';

export class GroupsList extends BaseComponent {
    /**
     * @inheritdoc
     */
    get template() {
        return template;
    }

    /**
     * @inheritdoc
     */
    get properties() {
        return {
            activeGroup: String,
            groups: Array,
        };
    }
}

define('groups-list', GroupsList);