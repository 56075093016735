/**
 * Convert temperature from degrees Celsius to degrees Kelvin.
 *
 * @param {Number} temperatureC temperature in degrees Celsius.
 * @return {Number} converted temperature.
 */
export const toKelvin = (temperatureC) => temperatureC + 273;

/**
 * Convert temperature from degrees Kelvin to degrees Celsius.
 *
 * @param {Number} temperatureK temperature in degrees Kelvin.
 * @return {Number} converted temperature.
 */
export const toCelsius = (temperatureK) => temperatureK - 273;