export const SearchMixin = (SuperClass) => class extends SuperClass {
    async initialize(...args) {
        await super.initialize(...args);
        const search = this.factory('search');
        search.on('results', (results, { imFeelingLucky } = {}) => {
            if (imFeelingLucky && results.length) {
                this.activeElement = results[0];
                this.highlightElements = null;
            } else {
                this.highlightElements = results;
            }
        });
    }
};
