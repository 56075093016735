import { DOM } from '@dnajs/idom';

export const PageMixin = (SuperClass) => class extends SuperClass {
    /**
     * @inheritdoc
     */
    get properties() {
        return {
            lang: String,
            highlightElements: Array,
            activeElement: Object,
        };
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'click .periodic-table .table-element': this.onElementClick,
        };
    }

    /**
     * @inheritdoc
     */
    async exec(lang, ...args) {
        this.lang = lang;
        this.chemicalElements = this.factory('data').getData();
        const { router } = this.getContext();
        let lastRouteItem = args[args.length - 1];
        if (!isNaN(lastRouteItem)) {
            let number = parseInt(lastRouteItem) - 1;
            if (this.chemicalElements[number]) {
                this.activeElement = this.chemicalElements[number];
            }
            this.baseRoute = router.current.replace(`/${lastRouteItem}`, '');
        } else {
            this.baseRoute = router.current;
        }
        this.observeProperty('activeElement', this.setElementHash.bind(this));
        return await super.exec(lang, ...args);
    }

    /**
     * Handle table element click.
     *
     * @param {Event} ev
     * @param {Node} target
     */
    onElementClick(ev, target) {
        const element = DOM.getNodeComponent(target).chemicalElement;
        if (this.isElementDisabled(element)) {
            return;
        }
        this.activeElement = element;
    }

    /**
     * Determines if a table element is disabled. ♿️
     *
     * @param {Object} element Chemical element.
     * @return {Boolean}
     */
    isElementDisabled(/* element */) {
        return false;
    }

    /**
     * Set element's atomic number in hash on element activation.
     */
    async setElementHash() {
        let app = this.getContext();
        let router = app.router;
        let route;
        app.changingActiveElement = true;
        if (this.activeElement) {
            route = `${this.baseRoute.replace(/\/$/, '')}/${this.activeElement.atomic_number}`;
        } else {
            route = this.baseRoute;
        }
        await router.navigate(route, document.title, true);
        app.changingActiveElement = false;
    }
};
