import 'https://cdn.polyfill.io/v3/?features=Array.from,Element.prototype.matches,Array.prototype.find,Element.prototype.remove,Array.prototype.findIndex,Promise,Array.prototype.includes,Symbol,Array.prototype.some,WeakMap,CustomEvent,fetch,Element.prototype.closest,URL';
import PeriodicTableApp from './app.js';

if (self.cordova) {
    document.addEventListener('deviceready', () => {
        self.APP = new PeriodicTableApp(document.getElementById('app'));
    }, false);
} else {
    self.APP = new PeriodicTableApp(document.getElementById('app'));
}
