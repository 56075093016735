import { trust } from '@dnajs/idom';
import { __ } from '../../factories/i18n';
import { toCelsius } from '../../helpers/temperature.js';
import { IS_KIDS } from '../../config/features.js';

/**
 * Return data handling categories and app mode exceptions.
 */
function dataByCategory() {
    if (this.chemicalElement[this.category] == null) {
        return '/';
    }
    switch (this.category) {
        case 'atomic_mass':
            if (this.chemicalElement.atomic_mass_estimated) {
                return trust(`[${this.chemicalElement.atomic_mass}]`);
            }
            return trust(this.chemicalElement.atomic_mass);
        case 'discovery':
            if (this.chemicalElement.known_in_ancient_ages) {
                return __('ancient ages');
            }
            return trust(this.chemicalElement[this.category]);
        case 'boiling_temperature':
        case 'melting_temperature':
            if (IS_KIDS) {
                return toCelsius(this.chemicalElement[this.category]);
            }
            return this.chemicalElement[this.category];
        default:
            return trust(this.chemicalElement[this.category]);
    }
}

this.chemicalElement && [
    (!this.category || !(this.category in this.chemicalElement)) && <span class="atomic-number">{this.chemicalElement.atomic_number}</span>,
    <h4 class="symbol">{this.chemicalElement.symbol}</h4>,
    <p class="name">{this.chemicalElement.title[this.lang]}</p>,
    this.category && this.category in this.chemicalElement && <p class="data">{dataByCategory.call(this)}</p>,
];
