import i18next from 'i18next';
import { Factory } from '@chialab/synapse';

let I18N;

/**
 * "Promisify" the i18next initialization.
 * @type {Promise}
 */
const LOAD_PROMISE = new Promise((resolve, reject) => {
    i18next.init({
        fallbackLng: 'it',
    }, (err) => {
        if (err) {
            reject(err);
        } else {
            resolve();
        }
    });
});

/**
 * Helper for internationalization and project's labels.
 * @class I18N
 */
export default class I18NFactory extends Factory {
    /**
     * @inheritdoc
     */
    constructor(...args) {
        super(...args);
        I18N = this;
    }

    /**
     * @inheritdoc
     */
    async initialize(dictionaries, lang) {
        await super.initialize();
        await LOAD_PROMISE;
        Object.keys(dictionaries || {}).map((lang) => this.addResources(lang, dictionaries[lang]));
        if (lang) {
            this.setLanguage(lang);
        }
    }
    /**
     * Translate a label.
     * @memberof I18N
     * @param {string} key The label to translate.
     * @param {Object} params An object containing parameters for interpolation.
     * @return {string}
     */
    translate(key, params = {}) {
        return i18next.t(key, params);
    }

    /**
     * Dynamically add resources for the given language.
     * @memberof I18N
     * @param {string} lang The language to setup.
     * @param {Object} resources A set of key/value resources for the given language.
     * @return {Promise}
     */
    addResources(lang, resources) {
        i18next.addResources(lang, 'translation', resources);
    }

    /**
     * Set the current language.
     * @memberof I18N
     * @param {string} lang The language to set.
     * @return {Promise}
     */
    setLanguage(lang) {
        if (this.currentLanguage === lang) {
            return;
        }
        i18next.changeLanguage(lang);
        this.trigger('change', lang);
    }

    /**
     * Get current language.
     * @memberof I18N
     * @return {string}
     */
    get currentLanguage() {
        return i18next.language;
    }
}

/**
 * Short-hand method for I18N translation.
 * @param {string} key The label to translate.
 * @param {Object} params An object containing parameters for interpolation.
 * @return {string}
 */
export function __(key, params) {
    return I18N.translate(key, params) || key;
}
