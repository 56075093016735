import { PageViewComponent } from '@chialab/synapse';
import { DOM, trust, mix, define } from '@dnajs/idom';
import { __ } from '../../factories/i18n.js';
import { PageMixin } from '../../mixins/page.js';
import { SearchMixin } from '../../mixins/search.js';
import { IS_KIDS } from '../../config/features.js';
import UNITS from '../../config/units.js';
import { toCelsius } from '../../helpers/temperature.js';
import '../../components/periodic-table/periodic-table.js';
import '../../components/thermometer/thermometer.js';

export default class TemperatureAndStatesPage extends mix(PageViewComponent).with(PageMixin, SearchMixin) {
    /**
     * @inheritdoc
     */
    get template() {
        return () => [
            <table is="periodic-table"
                lang={this.lang}
                chemicalElements={this.chemicalElements}
                isElementDisabled={this.isElementDisabled}
                description={this.description}
                highlightElements={this.highlightElements}
                activeElement={this.activeElement}
                temperature={this.temperature}
                focusContent={() =>
                    [
                        <h3 class="description-category" data-active={!!this.activeElement}>{__('temperature and states')}</h3>,
                        !this.activeElement && <p class="description-page">{__('temperature and states assumption')}</p>,
                        this.activeElement && [
                            <h2 class="description-title">{trust(this.activeElement.title[this.lang])}</h2>,
                            <a class="melting-temperature" href={`${this.lang}/thematic-tables/melting-temperature/${this.activeElement.atomic_number}`}>
                                <label>{__('melting_temperature')}: </label>
                                <span>
                                    {this.activeElement.melting_temperature != null ? `${IS_KIDS ? toCelsius(this.activeElement.melting_temperature) : this.activeElement.melting_temperature} ${UNITS['melting_temperature']}` : '/'}
                                    <span class="thematic-link">
                                        <ieb-icon name="arrow-dx" />
                                    </span>
                                </span>
                            </a>,
                            <a class="boiling-temperature" href={`${this.lang}/thematic-tables/boiling-temperature/${this.activeElement.atomic_number}`}>
                                <label>{__('boiling_temperature')}: </label>
                                <span>
                                    {this.activeElement.boiling_temperature != null ? `${IS_KIDS ? toCelsius(this.activeElement.boiling_temperature) : this.activeElement.boiling_temperature} ${UNITS['boiling_temperature']}` : '/'}
                                    <span class="thematic-link">
                                        <ieb-icon name="arrow-dx" />
                                    </span>
                                </span>
                            </a>,
                            <div class="standard-state">
                                <label>{__('standard_physical_state')}: </label>
                                <span>{__(this.activeElement.standard_physical_state || '/')}</span>
                            </div>,
                        ],
                    ]
                }
                toolsContent={function() {
                    return [
                        <thermometer temperature={this.temperature} />,
                        <div class="states-legend">
                            <label class="legend-text solid">{__('solid')}</label>
                            <label class="legend-text liquid">{__('liquid')}</label>
                            <label class="legend-text gas">{__('gas')}</label>
                        </div>,
                    ];
                }}></table>,
        ];
    }

    /**
     * @inheritdoc
     */
    get events() {
        return {
            'temperature-change': (ev) => {
                this.temperature = ev.detail.temperature;
                this.setTemperature();
            },
        };
    }

    /**
     * @inheritdoc
     */
    connectedCallback() {
        this.temperature = 273 + 25;
        super.connectedCallback();
    }

    /**
     * Set temperature to table instance.
     *
     * @memberof TemperatureAndStatesPage
     */
    setTemperature() {
        let table = this.querySelector('.periodic-table');
        if (table) {
            let tableInstance = DOM.getNodeComponent(table);
            tableInstance.temperature = this.temperature;
        }
    }

    /**
     * @inheritdoc
     */
    isElementDisabled(element) {
        return element.boiling_temperature == null &&
            element.melting_temperature == null &&
            element.standard_physical_state == null;
    }
}

define('temperature-and-states-page', TemperatureAndStatesPage);
