import { __ } from '../../factories/i18n.js';
import { IS_KIDS } from '../../config/features.js';

<div class="dialog-wrapper" data-nosnippet>
    <div class="dialog-content">
        <p>{__('This application is also available on the stores for offline usage')}</p>
        {!IS_KIDS && <a href="https://play.google.com/store/apps/details?id=it.chialab.periodictable" target="_blank">
            <img src="assets/google_play.svg" aria-hidden />
        </a>}
        {!IS_KIDS && <a href="https://itunes.apple.com/it/app/tavola-periodica-degli-elementi/id742242888" target="_blank">
            <img src="assets/app_store.svg" aria-hidden />
        </a>}
        {IS_KIDS && <a href="https://play.google.com/store/apps/details?id=it.chialab.periodictablekids" target="_blank">
            <img src="assets/google_play.svg" aria-hidden />
        </a>}
        {IS_KIDS && <a href="https://itunes.apple.com/it/app/tavola-periodica-kids/id743611957" target="_blank">
            <img src="assets/app_store.svg" aria-hidden />
        </a>}
    </div>
    <div class="dialog-commands">
        <button>{__('continue online navigation')}</button>
    </div>
</div>;
