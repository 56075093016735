import { Controller } from '@chialab/synapse';
import TablePage from '../pages/table/table.js';
import TemperatureAndStatesPage from '../pages/temperature-and-states/temperature-and-states.js';
import ConfigurationPage from '../pages/configuration/configuration.js';
import ClassesPage from '../pages/classes/classes.js';
import ThematicPage from '../pages/thematic/thematic.js';
import MatchSymbolsPage from '../pages/training/match-symbols/match-symbols.js';
import FindElementPage from '../pages/training/find-element/find-element.js';
import ArrangeElementsPage from '../pages/training/arrange-elements/arrange-elements.js';
import FindClassesPage from '../pages/training/find-classes/find-classes.js';
import FindStatePage from '../pages/training/find-state/find-state.js';

const redirect = (path) => class extends Controller {
    exec() {
        this.getContext().navigate(path, document.title, true);
    }
};

export default {
    '/:lang': TablePage,
    '/:lang/training/arrange-elements/': ArrangeElementsPage,
    '/:lang/training/find-classes/': FindClassesPage,
    '/:lang/training/find-element/': FindElementPage,
    '/:lang/training/find-state/': FindStatePage,
    '/:lang/training/match-symbols/': MatchSymbolsPage,
    '/:lang/training/arrange-elements/:level': ArrangeElementsPage,
    '/:lang/training/find-classes/:level': FindClassesPage,
    '/:lang/training/find-element/:level': FindElementPage,
    '/:lang/training/find-state/:level': FindStatePage,
    '/:lang/training/match-symbols/:level': MatchSymbolsPage,
    '/:lang/thematic-tables/temperature-and-states/*': TemperatureAndStatesPage,
    '/:lang/thematic-tables/temperature-and-states': TemperatureAndStatesPage,
    '/:lang/thematic-tables/electronic-configuration/*': ConfigurationPage,
    '/:lang/thematic-tables/electronic-configuration': ConfigurationPage,
    '/:lang/thematic-tables/classes/*': ClassesPage,
    '/:lang/thematic-tables/classes': ClassesPage,
    '/:lang/thematic-tables/:category': ThematicPage,
    '/:lang/thematic-tables/:category/*': ThematicPage,
    '/:lang/*': TablePage,
    '*': redirect('/it/'),
};
