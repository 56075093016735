import { Factory } from '@chialab/synapse';

export default class SearchFactory extends Factory {
    /**
     * Search an element by search text.
     * Search is performed on `atomic_number`, `symbol` or `title`.
     *
     * @param {string} searchText User input.
     * @param {boolean} imFeelingLucky Should select the first element found.
     * @return {Object} found element.
     * @memberof SearchFactory
     */
    searchElement(searchText, imFeelingLucky) {
        const data = this.factory('data');
        const i18n = this.factory('i18n');

        const elements = data.getData();
        const parsedNum = parseInt(searchText);

        searchText = searchText.toLowerCase();

        let results = [];
        if (!isNaN(parsedNum)) {
            let res = elements.find((element) => element.atomic_number === parsedNum);
            if (res) {
                results.push(res);
            }
        } else {
            let res = elements.find((element) => element.symbol.toLowerCase() === searchText);
            if (res) {
                results.push(res);
            } else {
                res = elements.find((element) => element.title[i18n.currentLanguage].toLowerCase() === searchText);
                if (res) {
                    results.push(res);
                } else if (searchText.length > 1) {
                    results = elements.filter((element) => {
                        if (element.title[i18n.currentLanguage].toLowerCase().includes(searchText.toLowerCase())) {
                            return true;
                        }
                        return false;
                    });
                }
            }
        }

        this.results = results;
        this.trigger('results', results, {
            imFeelingLucky,
        });
        return results;
    }

    reset() {
        this.results = [];
        this.trigger('results', null);
    }
}
