import { Factory } from '@chialab/synapse';
import elements from '../data/data.json';
import exercises_it from '../data/training/ita.json';
import exercises_en from '../data/training/eng.json';
import GROUPS from '../config/groups.js';
import LEVELS from '../config/levels.js';

export default class DataFactory extends Factory {
    async initialize(...args) {
        await super.initialize(...args);
        let lastConfig = {};
        this.quantumNumbers = elements.map((element) => {
            let config = this.getConfigurationData(element);
            if (!config) {
                return null;
            }
            let sum = {
                s: Object.keys(config.s || {}).reduce((count, key) => count += config.s[key], 0),
                p: Object.keys(config.p || {}).reduce((count, key) => count += config.p[key], 0),
                d: Object.keys(config.d || {}).reduce((count, key) => count += config.d[key], 0),
                f: Object.keys(config.f || {}).reduce((count, key) => count += config.f[key], 0),
            };
            let main;
            let secondary;
            let magnetic;
            let group;
            if (sum.f > lastConfig.f) {
                group = 'f';
                main = Object.keys(config.f).pop();
                secondary = 3;
            } else if (sum.d > lastConfig.d) {
                group = 'd';
                main = Object.keys(config.d).pop();
                secondary = 2;
            } else if (sum.p > lastConfig.p) {
                group = 'p';
                main = Object.keys(config.p).pop();
                secondary = 1;
            } else {
                group = 's';
                main = Object.keys(config.s).pop();
                secondary = 0;
            }
            lastConfig = sum;
            if (main) {
                let max = LEVELS[main - 1][group];
                let n = (config[group][main] - 1) % (max / 2);
                magnetic = n - (max / 2 - 1) / 2;
            }
            return {
                main,
                secondary,
                magnetic,
            };
        });
        return this;
    }

    /**
     * Return chemical elements.
     *
     * @return {Array}
     * @memberof DataFactory
     */
    getData() {
        return elements;
    }

    /**
     * Return exercises data according to language.
     *
     * @param {string} lang
     * @return {Array}
     * @memberof DataFactory
     */
    getExercises(lang) {
        if (lang == 'en') {
            return exercises_en;
        }
        return exercises_it;
    }

    /**
     * Return translated and sorted groups (ascending order).
     *
     * @return {Array}
     * @memberof DataFactory
     */
    getTranslatedGroups() {
        const i18n = this.factory('i18n');
        return GROUPS.map((group) => ({ label: i18n.translate(group.key), name: group.name }))
            .sort((a, b) => {
                const a1 = a.label.toLowerCase();
                const b1 = b.label.toLowerCase();
                if (a1 < b1) {
                    return -1;
                } else if (a1 > b1) {
                    return 1;
                }
                return 0;
            });
    }

    /**
     * Return row number which `element` belongs to.
     *
     * @param {Object} element Chemical element data.
     * @return {Number}
     * @memberof DataFactory
     */
    getElementRow(element) {
        let number = element.atomic_number;

        if (number <= 2) {
            return 1;
        }

        if (number <= 10) {
            return 2;
        }

        if (number <= 18) {
            return 3;
        }

        if (number <= 36) {
            return 4;
        }

        if (number <= 54) {
            return 5;
        }

        if (number <= 71 || (number >= 72 && number <= 86)) {
            return 6;
        }

        return 7;
    }

    /**
     * Return column number which `element` belongs to.
     *
     * @param {Object} element Chemical element data.
     * @return {Number}
     * @memberof DataFactory
     */
    getElementColumn(element) {
        let number = element.atomic_number;

        if ([1, 3, 11, 19, 37, 55, 87].includes(number)) {
            return 1;
        }

        if ([4, 12, 20, 38, 56, 88].includes(number)) {
            return 2;
        }

        if ([21, 39].includes(number) || (number >= 57 && number <= 71) || (number >= 89 && number <= 103)) {
            return 3;
        }

        if ([22, 40, 72, 104].includes(number)) {
            return 4;
        }

        if ([23, 41, 73, 105].includes(number)) {
            return 5;
        }

        if ([24, 42, 74, 106].includes(number)) {
            return 6;
        }

        if ([25, 43, 75, 107].includes(number)) {
            return 7;
        }

        if ([26, 44, 76, 108].includes(number)) {
            return 8;
        }

        if ([27, 45, 77, 109].includes(number)) {
            return 9;
        }

        if ([28, 46, 78, 110].includes(number)) {
            return 10;
        }

        if ([29, 47, 79, 111].includes(number)) {
            return 11;
        }

        if ([30, 48, 80, 112].includes(number)) {
            return 12;
        }

        if ([5, 13, 31, 49, 81, 113].includes(number)) {
            return 13;
        }

        if ([6, 14, 32, 50, 82, 114].includes(number)) {
            return 14;
        }

        if ([7, 15, 33, 51, 83, 115].includes(number)) {
            return 15;
        }

        if ([8, 16, 34, 52, 84, 116].includes(number)) {
            return 16;
        }

        if ([9, 17, 35, 53, 85, 117].includes(number)) {
            return 17;
        }

        return 18;
    }

    getElementbySymbol(symbol) {
        symbol = symbol.toLowerCase();
        let elements = this.getData();
        return elements.find((element) => element.symbol.toLowerCase() === symbol);
    }

    getFullConfiguration(element) {
        let configuration = element.electronic_configuration;
        if (!configuration) {
            return null;
        }
        while (configuration[0] === '[') {
            let match = configuration.match(/^\[(\w*)\](.*)/);
            let supElement = this.getElementbySymbol(match[1]);
            configuration = `${supElement.electronic_configuration}${match[2]}`;
        }
        return configuration;
    }

    getConfigurationData(element) {
        let configuration = this.getFullConfiguration(element);
        if (!configuration) {
            return null;
        }
        configuration = configuration.replace(/<sup>/g, '').split('</sup>').slice(0, -1);
        return configuration.reduce((map, entry) => {
            let match = entry.match(/(\d+)(\w)(\d+)/);
            let group = map[match[2]] = map[match[2]] || {};
            group[match[1]] = parseInt(match[3]);
            return map;
        }, {});
    }

    getMainQuantumNumber(element) {
        return this.quantumNumbers[element.atomic_number - 1] && this.quantumNumbers[element.atomic_number - 1].main;
    }

    getSecondaryQuantumNumber(element) {
        return this.quantumNumbers[element.atomic_number - 1] && this.quantumNumbers[element.atomic_number - 1].secondary;
    }

    getMagneticQuantumNumber(element) {
        return this.quantumNumbers[element.atomic_number - 1] && this.quantumNumbers[element.atomic_number - 1].magnetic;
    }
}
